// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DriverCheckForm_container__pV9nb {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.DriverCheckForm_sigCanvas__ig6Po {
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 100%;
}

.DriverCheckForm_stepInfo__vbnkX {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
}

.DriverCheckForm_loadingOverlay__z1qrG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.DriverCheckForm_loadingContent__ydQXb {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

progress {
    width: 100%;
    height: 20px;
    margin-top: 10px;
    border-radius: 5px;
    overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/css/DriverCheckForm.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,yCAAyC;AAC7C;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".container {\r\n    max-width: 400px;\r\n    margin: 0 auto;\r\n    padding: 20px;\r\n    text-align: center;\r\n}\r\n\r\n.sigCanvas {\r\n    border: 1px solid #ccc;\r\n    border-radius: 8px;\r\n    width: 100%;\r\n}\r\n\r\n.stepInfo {\r\n    margin-top: 20px;\r\n    font-size: 18px;\r\n    font-weight: bold;\r\n}\r\n\r\n.loadingOverlay {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background-color: rgba(0, 0, 0, 0.5);\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    z-index: 1000;\r\n}\r\n\r\n.loadingContent {\r\n    background-color: white;\r\n    padding: 20px;\r\n    border-radius: 8px;\r\n    text-align: center;\r\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);\r\n}\r\n\r\nprogress {\r\n    width: 100%;\r\n    height: 20px;\r\n    margin-top: 10px;\r\n    border-radius: 5px;\r\n    overflow: hidden;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DriverCheckForm_container__pV9nb`,
	"sigCanvas": `DriverCheckForm_sigCanvas__ig6Po`,
	"stepInfo": `DriverCheckForm_stepInfo__vbnkX`,
	"loadingOverlay": `DriverCheckForm_loadingOverlay__z1qrG`,
	"loadingContent": `DriverCheckForm_loadingContent__ydQXb`
};
export default ___CSS_LOADER_EXPORT___;
