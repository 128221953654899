import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import styles from '../DriverAssignDetail.module.css';

const DriverAssignDetails = () => {
  const { customIdF } = useParams();
  const navigate = useNavigate();
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('Token not found');
          return;
        }

        const response = await axios.get(`https://ate69.fr:5003/api/convoyages/${customIdF}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data && Array.isArray(response.data) && response.data.length > 0) {
          setDetails(response.data[0]);
        } else {
          console.error('No data found or data is not an array');
        }
      } catch (error) {
        console.error('Failed to fetch details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [customIdF]);

  const formatDateTime = (dateString) => {
    return dateString 
      ? new Date(dateString).toLocaleString('fr-FR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        })
      : 'Non disponible';
  };

  const formatAddress = (address) => {
    if (!address) return 'Adresse non disponible';
    const { streetName, number, city, postalCode, streetType } = address;
    return `${number} ${streetType || ''} ${streetName}, ${city}, ${postalCode}`;
  };

  const handleStartConvoyage = () => {
    navigate(`/driver-check-form/${customIdF}`);
  };

  const handleFinishConvoyage = () => {
    navigate(`/driver-check-form-del/${customIdF}`);
  };

  if (loading) {
    return <div>Chargement des détails...</div>;
  }

  if (!details) {
    return <div>Impossible de charger les détails du convoyage.</div>;
  }

  return (
    <div className={styles.card}>
      <div className={styles.infoPanel}>
        <div className={styles.section}>
          <p><strong>ID du Convoyage:</strong> {details.customIdF || 'Non disponible'}</p>
          <div className={styles.dateTime}>{formatDateTime(details.pickupDetails?.pickupDate)}</div>
          <div className={styles.address}>De: {formatAddress(details.clientDetails?.address)} (Domicile)</div>
          <div className={styles.address}>À: {formatAddress(details.pickupDetails?.address)} (Enlèvement)</div>
        </div>
        <div className={styles.section}>
          <div className={styles.address}>De: {formatAddress(details.pickupDetails?.address)} (Enlèvement)</div>
          <div className={styles.address}>À: {formatAddress(details.deliveryDetails?.address)} (Livraison)</div>
        </div>
        <div className={styles.section}>
          <div className={styles.dateTime}>{formatDateTime(details.deliveryDetails?.deliveryDate)}</div>
          <div className={styles.address}>De: {formatAddress(details.deliveryDetails?.address)} (Livraison)</div>
          <div className={styles.address}>À: {formatAddress(details.clientDetails?.address)} (Retour / Suivant)</div>
        </div>
      </div>
      <div className={styles.detailsPanel}>
        <div>Compagnie: {details.clientDetails?.company || 'Non disponible'}</div>
        <div>Marque: {details.vehicleDetails?.brand || 'Non disponible'}</div>
        <div>Modèle: {details.vehicleDetails?.model || 'Non disponible'}</div>
        <div>Immatriculation: {details.vehicleDetails?.registration || 'Non disponible'}</div>
        <div>Distance: {details.pricingDetails?.distance || 'Non disponible'}</div>
        <div>Durée: {details.pricingDetails?.duration || 'Non disponible'}</div>
        <h4>Estimation des frais de routes de la mission</h4>
        <div>Péage : {details.pricingDetails?.tollCostEstimate ? `${details.pricingDetails.tollCostEstimate}€` : 'Non disponible'}</div>
        <div>Carburant : {details.pricingDetails?.fuelCostEstimate ? `${details.pricingDetails.fuelCostEstimate}€` : 'Non disponible'}</div>
      </div>
      <div className={styles.actions}>
        {details.status === 'validated' && (
          <button onClick={handleStartConvoyage}>Démarrer</button>
        )}
        {details.status === 'started' && (
          <button onClick={handleFinishConvoyage}>Clôturer</button>
        )}
      </div>
    </div>
  );
};

export default DriverAssignDetails;
