import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faGoogle } from '@fortawesome/free-brands-svg-icons';
import 'animate.css/animate.min.css';
import '../Footer.css';

function Footer() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById('contact');
      if (element) {
        const rect = element.getBoundingClientRect();
        setIsVisible(rect.top < window.innerHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <footer id="contact" className={`contact-footer ${isVisible ? 'in-view' : ''}`}>
      <div className="footer-content">
        <div className="footer-section contact">
          <h3>Contactez-nous</h3>
          <p>Notre centre d’exploitation n’est malheureusement pas ouvert au public.</p>
          <p>+33 7 83 91 31 22</p>
          <p><a href="mailto:ate69005@gmail.com">ate69005@gmail.com</a></p>
          <p>21 Quai Fulchiron, 69005 LYON</p>
        </div>
        <div className="footer-section links">
          <h3>Liens Utiles</h3>
          <ul>
            <li><Link to="/">Accueil</Link></li>
            <li><Link to="/register">Register</Link></li>
            <li><Link to="/login">Login</Link></li>
            <li><Link to="/faq">FAQ</Link></li>
            <li><Link to="/cgv">CGV</Link></li>
            <li><Link to="/privacy">Politique de confidentialité</Link></li> {/* Lien ajouté */}
          </ul>
        </div>
        <div className="footer-section social">
          <h3>Suivez-nous</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=100088499901338" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} className="icon" />
            </a>
            <a href="https://www.instagram.com/ate69005/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} className="icon" />
            </a>
            <a href="https://g.page/r/CfdzS8xtBAjDEBM/review" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGoogle} className="icon" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Auto Transport Express 69, ATE GROUP. Tous droits réservés.</p>
        <div>
          <Link to="/privacy">Politique de confidentialité</Link>
          <Link to="/terms-of-service">Conditions d'utilisation</Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
