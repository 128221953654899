import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import '../ValidationWaitPage.css';

function ValidationWaitPage() {
    return (
        <div className="validation-wait-page">
            <Helmet>
                <title>Validation en attente</title>
                <meta name="description" content="Votre compte est en cours de validation. Veuillez patienter." />
            </Helmet>
            <div className="content">
                <h1>Validation en attente</h1>
                <p>Votre compte est actuellement en cours de validation par nos équipes. Nous vérifions les demandes du lundi au vendredi, de 9h à 18h.</p>
                <p>Le processus de validation peut prendre jusqu'à 48 heures. Nous vous remercions pour votre patience.</p>
                <p>En cas d'urgence, vous pouvez nous contacter par mail à l'adresse suivante : <a href="mailto:ate69005@gmail.com">ate69005@gmail.com</a> ou par téléphone au <a href="tel:0783913122">0783913122</a>.</p>
                <p>Veuillez noter que nous pouvons vous demander des informations complémentaires pour finaliser la validation de votre compte. Nous nous réservons également le droit de valider ou de refuser votre demande selon les critères établis.</p>
                <div className="spinner"></div>
                <div className="buttons">
                    <Link to="/" className="btn">Accueil</Link>
                    <Link to="/faq" className="btn">F.A.Q</Link>
                    <Link to="/cgv" className="btn">C.G.V</Link>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ValidationWaitPage;
