import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

function DriverFormViewEnl() {
    const { customIdF } = useParams();
    const navigate = useNavigate();
    const [convoyageDetails, setConvoyageDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const user = JSON.parse(localStorage.getItem('user'));
    const isDriver = user?.role === 'driver';

    useEffect(() => {
        const fetchConvoyageDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('Authentication token is not available.');
                }
                const response = await axios.get(`https://ate69.fr:5003/api/convoyages/${customIdF}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                if (response.data && response.data.length > 0) {
                    setConvoyageDetails(response.data[0]);
                } else {
                    setError('No convoyage details found.');
                }
            } catch (error) {
                console.error('Failed to fetch convoyage details:', error);
                setError('Failed to fetch convoyage details');
            } finally {
                setLoading(false);
            }
        };

        if (customIdF) {
            fetchConvoyageDetails();
        } else {
            setError('Convoyage ID is undefined.');
            setLoading(false);
        }
    }, [customIdF]);

    const formatAddress = (address) => {
        if (!address) return 'N/A';
        return `${address.number} ${address.streetType} ${address.streetName}, ${address.postalCode} ${address.city}`;
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!convoyageDetails) return <div>No details found.</div>;

    const photoFields = {
        pickupOdoPhoto: 'Photo Compteur',
        faceFrontPhoto: 'Photo Face Avant',
        wheelFrontLeftPhoto: 'Photo Roue Avant Gauche',
        wheelFrontRightPhoto: 'Photo Roue Avant Droite',
        seatFrontLeftPhoto: 'Photo Siège Avant Gauche',
        seatFrontRightPhoto: 'Photo Siège Avant Droit',
        seatRearLeftPhoto: 'Photo Siège Arrière Gauche',
        seatRearRightPhoto: 'Photo Siège Arrière Droit',
        rearLeftPhoto: 'Photo Arrière Gauche',
        rearRightPhoto: 'Photo Arrière Droite',
        wheelRearLeftPhoto: 'Photo Roue Arrière Gauche',
        wheelRearRightPhoto: 'Photo Roue Arrière Droite',
        rearPhoto: 'Photo Arrière',
        trunkOpenPhoto: 'Photo Coffre Ouvert',
        dossierEnlevement: 'Photo Dossier Enlèvement',
        contractPhoto: 'Photo Contrat',
    };

    const renderPhotos = () => {
        return (
            <table style={{ width: '100%', marginBottom: '20px', border: '1px solid #ccc' }}>
                <thead>
                    <tr>
                        <th>Nom de la Photo</th>
                        <th>Image</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(photoFields).map(([field, label]) => (
                        <tr key={field}>
                            <td>{label}</td>
                            <td>
                                {convoyageDetails.pickupCheck && convoyageDetails.pickupCheck[field] ? (
                                    <a href={convoyageDetails.pickupCheck[field]} target="_blank" rel="noopener noreferrer">
                                        <img 
                                            src={convoyageDetails.pickupCheck[field]} 
                                            alt={label} 
                                            style={{ width: '200px', height: '200px', objectFit: 'cover' }} 
                                        />
                                    </a>
                                ) : (
                                    'Aucune image disponible'
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div>
            <h2>Détails du Convoyage</h2>
            <p><strong>ID du Convoyage:</strong> {convoyageDetails.customIdF}</p>
            <p><strong>Client:</strong> {convoyageDetails.clientDetails?.name} {convoyageDetails.clientDetails?.surname}</p>
            <p><strong>Conducteur:</strong> {convoyageDetails.driverDetails?.name} {convoyageDetails.driverDetails?.surname}</p>
            <p><strong>Adresse d'enlèvement:</strong> {formatAddress(convoyageDetails.pickupDetails?.address)}</p>
            <p><strong>Adresse de livraison:</strong> {formatAddress(convoyageDetails.deliveryDetails?.address)}</p>
            <p><strong>Marque:</strong> {convoyageDetails.vehicleDetails?.brand}</p>
            <p><strong>Modèle:</strong> {convoyageDetails.vehicleDetails?.model}</p>
            <p><strong>Immatriculation:</strong> {convoyageDetails.vehicleDetails?.registration}</p>
            <p><strong>Kilométrage:</strong> {convoyageDetails.pickupCheck?.mileage || 'N/A'}</p>
            <p><strong>Niveau de carburant:</strong> {convoyageDetails.pickupCheck?.fuelLevel || '0'}%</p>
            <p><strong>Date de prise en charge:</strong> {convoyageDetails.pickupDetails?.pickupDate ? new Date(convoyageDetails.pickupDetails.pickupDate).toLocaleString() : 'N/A'}</p>
            <p><strong>Status:</strong> {convoyageDetails.status}</p>
            <h3>Photos:</h3>
            {renderPhotos()}
            {isDriver && convoyageDetails.status === 'started' && (
                <button onClick={() => navigate(`/driver-check-form-del/${customIdF}`)}>Clôturer</button>
            )}
            <button onClick={() => navigate(-1)}>Fermer</button>
        </div>
    );
}

export default DriverFormViewEnl;
