import React from 'react';
import { Helmet } from 'react-helmet';
import { useInView } from 'react-intersection-observer';
import '../Work.css';

const Work = ({ setTitle, setDescription }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });

  React.useEffect(() => {
    if (inView) {
      setTitle('ATE69 - Notre Travail');
      setDescription('Découvrez notre travail et nos réalisations.');
    }
  }, [inView, setTitle, setDescription]);

  return (
    <section id="work" className="work-section" ref={ref}>
      <Helmet>
        <title>ATE69 - Notre Travail</title>
        <meta name="description" content="Découvrez notre travail et nos réalisations." />
      </Helmet>
      <div className="work-container">
        <h2>Chaque voiture est différente !</h2>
        <p id="sous-titre" itemProp="description">
          Peu importe la catégorie de véhicule, nos convoyeurs sont spécialement qualifiés et formés pour gérer différents types de véhicules. Leur expertise est clairement identifiable grâce aux abréviations spécifiques apposées à leur signature. Nous disposons de trois catégories distinctes de convoyeurs, chacune adaptée à des besoins spécifiques, garantissant ainsi un service de convoyage professionnel et sur mesure à chacun de nos clients.
        </p>
        <div className="work-row">
          <div className="work-card">
            <img src={require('../../src/img/img_card1.jpg')} alt="Convoyeur automobile 1" />
            <h3>Convoyeurs “Infinite”</h3>
            <p>Nos convoyeurs ‘Infinite’ représentent l’élite de notre équipe. Spécialement formés pour la livraison de véhicules d’exception, ils possèdent une expertise pointue dans la manipulation de catégories spécifiques de véhicules. Cette expertise couvre une gamme variée incluant les voitures sportives, les hypercars, les véhicules historiques, ainsi que les camping-cars. Choisir un convoyeur ‘Infinite’, c’est opter pour un service de livraison haut de gamme, adapté aux véhicules qui requièrent une attention et une expertise particulières.</p>
          </div>
          <div className="work-card">
            <img src={require('../../src/img/img_card2.jpg')} alt="Convoyeur automobile 2" />
            <h3>Convoyeurs “Premium”</h3>
            <p>Ce niveau de service, le plus répandu au sein de notre entreprise, rassemble la majorité de nos convoyeurs qualifiés. Conçu spécialement pour satisfaire les besoins du marché automobile européen majoritaire, il cible une large gamme de véhicules, tant neufs que d’occasion, dont la valeur ne dépasse pas 80 000€. Nos professionnels dans cette catégorie possèdent une expertise approfondie dans la manipulation et le convoyage sécurisé de ces véhicules, garantissant un service de haute qualité adapté à vos besoins.</p>
          </div>
          <div className="work-card">
            <img src={require('../../src/img/img_card3.jpg')} alt="Convoyeur automobile 3" />
            <h3>Convoyeurs “Junior”</h3>
            <p>Chacun de nos convoyeurs commence sa carrière chez nous par cette étape cruciale. Cela leur permet de se familiariser en profondeur avec nos procédures internes ainsi qu’avec celles spécifiques à nos clients. Ils reçoivent une formation complète et rigoureuse de notre part. À l’issue de cette formation, ils acquièrent la compétence et la confiance nécessaires pour convoyer de manière autonome des véhicules d’occasion d’une valeur inférieure à 20 000€. Cette étape initiale assure que tous nos convoyeurs sont parfaitement équipés pour répondre aux standards élevés de service et de sécurité que nous promettons à nos clients.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Work;
