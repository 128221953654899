import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import SignaturePad from 'react-signature-canvas';
import ReservesDelivery from './ReservesDelivery';
import styles from '../css/DriverCheckForm.module.css';

function DriverCheckFormDel() {
    const { id } = useParams();
    const navigate = useNavigate();
    const sigPadLiv = useRef({});
    const sigPadDriver = useRef({});
    const [formState, setFormState] = useState({
        mileage: '',
        fuelLevel: '',
        deliveryOdoPhoto: null,
        seatFrontLeftPhoto: null,
        wheelFrontLeftPhoto: null,
        faceFrontLeftPhoto: null,
        faceFrontPhoto: null,
        faceFrontRightPhoto: null,
        wheelFrontRightPhoto: null,
        seatFrontRightPhoto: null,
        seatRearRightPhoto: null,
        wheelRearRightPhoto: null,
        rearRightPhoto: null,
        rearPhoto: null,
        trunkOpenPhoto: null,
        rearLeftPhoto: null,
        wheelRearLeftPhoto: null,
        seatRearLeftPhoto: null,
        reserves: [],
        contractPhoto: null,
        livSignature: '',
        driverSignature: '',
        peage: '',
        carburant: '',
        fraisPhoto: null,
        dossierLivraison: null
    });

    const [uploadProgress, setUploadProgress] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            setFormState(prevState => ({ ...prevState, [name]: files[0] }));
        } else {
            setFormState(prevState => ({ ...prevState, [name]: value }));
        }
    };

    const handleSignatureSave = (name, signatureRef) => {
        setFormState(prevState => ({
            ...prevState,
            [name]: signatureRef.current.toDataURL()
        }));
    };

    const handleAddReserve = (reserve) => {
        setFormState(prevState => ({
            ...prevState,
            reserves: [...prevState.reserves, reserve]
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        Object.entries(formState).forEach(([key, value]) => {
            if (value !== null && value !== '') {
                if (key === 'reserves') {
                    formData.append(key, JSON.stringify(value));
                } else {
                    formData.append(key, value);
                }
            }
        });

        setLoading(true);

        try {
            const token = localStorage.getItem('token');
            await axios.put(`https://ate69.fr:5003/api/convoyages/${id}/driver-check-del`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const totalLength = progressEvent.lengthComputable
                        ? progressEvent.total
                        : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    
                    if (totalLength) {
                        const progress = Math.round((progressEvent.loaded * 100) / totalLength);
                        setUploadProgress(progress);
                    }
                }
            });
            alert('Les détails du convoyage ont été mis à jour avec succès.');
            navigate('/driver/dashboard');
        } catch (error) {
            console.error('Échec de la soumission du formulaire:', error);
            alert('Échec de la mise à jour des détails du convoyage.');
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        navigate(-1);
    };

    return (
        <div>
            <h2>Formulaire de Vérification Conducteur - Livraison</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Kilométrage :</label>
                    <input type="number" name="mileage" value={formState.mileage} onChange={handleChange} />
                </div>
                <div>
                    <label>Niveau de carburant (%) :</label>
                    <select name="fuelLevel" value={formState.fuelLevel} onChange={handleChange}>
                        <option value="">Sélectionner</option>
                        <option value="0">0%</option>
                        <option value="25">25%</option>
                        <option value="50">50%</option>
                        <option value="75">75%</option>
                        <option value="100">100%</option>
                    </select>
                </div>
                <div>
                    <label>Photo de l'odomètre à la livraison :</label>
                    <input type="file" accept="image/*" capture="environment" name="deliveryOdoPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo siège avant gauche :</label>
                    <input type="file" accept="image/*" capture="environment" name="seatFrontLeftPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo roue avant gauche :</label>
                    <input type="file" accept="image/*" capture="environment" name="wheelFrontLeftPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo face avant gauche :</label>
                    <input type="file" accept="image/*" capture="environment" name="faceFrontLeftPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo face avant :</label>
                    <input type="file" accept="image/*" capture="environment" name="faceFrontPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo face avant droite :</label>
                    <input type="file" accept="image/*" capture="environment" name="faceFrontRightPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo roue avant droite :</label>
                    <input type="file" accept="image/*" capture="environment" name="wheelFrontRightPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo siège avant droit :</label>
                    <input type="file" accept="image/*" capture="environment" name="seatFrontRightPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo siège arrière droit :</label>
                    <input type="file" accept="image/*" capture="environment" name="seatRearRightPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo roue arrière droite :</label>
                    <input type="file" accept="image/*" capture="environment" name="wheelRearRightPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo arrière droit :</label>
                    <input type="file" accept="image/*" capture="environment" name="rearRightPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo arrière :</label>
                    <input type="file" accept="image/*" capture="environment" name="rearPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo coffre ouvert :</label>
                    <input type="file" accept="image/*" capture="environment" name="trunkOpenPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo arrière gauche :</label>
                    <input type="file" accept="image/*" capture="environment" name="rearLeftPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo roue arrière gauche :</label>
                    <input type="file" accept="image/*" capture="environment" name="wheelRearLeftPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo siège arrière gauche :</label>
                    <input type="file" accept="image/*" capture="environment" name="seatRearLeftPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Réserves non présentes à l'enlèvement :</label>
                    {formState.reserves.map((reserve, index) => (
                        <div key={index}>
                            <p><strong>Partie Endommagée:</strong> {reserve.part}</p>
                            <p><strong>Description:</strong> {reserve.description}</p>
                            {reserve.photo && (
                                <div>
                                    <img src={URL.createObjectURL(reserve.photo)} alt="Reserve Photo" style={{ width: '50px', height: '50px' }} />
                                </div>
                            )}
                        </div>
                    ))}
                    <ReservesDelivery onAddReserve={handleAddReserve} />
                </div>
                <div>
                    <label>Photo du contrat :</label>
                    <input type="file" accept="image/*" capture="environment" name="contractPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Signature réception :</label>
                    <SignaturePad ref={sigPadLiv} penColor='black' canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} />
                    <button type="button" onClick={() => handleSignatureSave('livSignature', sigPadLiv)}>Enregistrer la signature de réception</button>
                </div>
                <div>
                    <label>Signature du conducteur :</label>
                    <SignaturePad ref={sigPadDriver} penColor='black' canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} />
                    <button type="button" onClick={() => handleSignatureSave('driverSignature', sigPadDriver)}>Enregistrer la signature du conducteur</button>
                </div>
                <div>
                    <label>Péage :</label>
                    <input type="number" name="peage" step="0.01" value={formState.peage} onChange={handleChange} />
                </div>
                <div>
                    <label>Carburant :</label>
                    <input type="number" name="carburant" step="0.01" value={formState.carburant} onChange={handleChange} />
                </div>
                <div>
                    <label>Photo des frais :</label>
                    <input type="file" accept="image/*" capture="environment" name="fraisPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Dossier Livraison :</label>
                    <input type="file" accept="image/*" capture="environment" name="dossierLivraison" onChange={handleChange} />
                </div>
                <button type="submit">Soumettre</button>
                <button type="button" onClick={handleClose}>Fermer</button>
            </form>

            {loading && (
                <div className={styles.loadingOverlay}>
                    <div className={styles.loadingContent}>
                        <p>Convoyage en cours de soumission... {uploadProgress}%</p>
                        <progress value={uploadProgress} max="100"></progress>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DriverCheckFormDel;
