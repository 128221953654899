import React from 'react';
import { Helmet } from 'react-helmet';
import { useInView } from 'react-intersection-observer';
import '../Success.css';

const Success = ({ setTitle, setDescription }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });

  React.useEffect(() => {
    if (inView) {
      setTitle('ATE69 - Nos Réussites');
      setDescription('Découvrez nos réussites et témoignages.');
    }
  }, [inView, setTitle, setDescription]);

  return (
    <section id="success" className="success-section" ref={ref}>
      <Helmet>
        <title>ATE69 - Nos Réussites</title>
        <meta name="description" content="Découvrez nos réussites et témoignages." />
      </Helmet>
      <div className="success-container">
        <h2 id="titre-success">Nos Réussites</h2>
        <div className="success-row">
          <div className="success-card">
            <img src={require('../../src/img/img_card4.png')} alt="Secret du succès" />
            <h3>Le secret de notre succès</h3>
            <p className="text-justify">Contrairement à de nombreux services de convoyage souvent basés sur des plateformes à bas coût, notre entreprise se distingue par un engagement fort envers la qualité et la fiabilité. Nous ne sous-traitons aucune de nos demandes ! Chaque convoyeur dans notre équipe possède au minimum dix ans d’expérience dans le transport, garantissant une connaissance approfondie et un savoir-faire exceptionnel. En choisissant nos services, vous optez pour l’excellence, la sécurité et un service de convoyage automobile sur mesure, géré par des experts du domaine.</p>
          </div>
          <div className="success-card">
            <img src={require('../../src/img/img_card5.png')} alt="Plateforme de réservation" />
            <h3>Plateforme de réservation autonome</h3>
            <p className="text-justify">Notre plateforme de réservation est entièrement autonome, conçue à la main et sans utiliser de CMS. Elle offre une expérience utilisateur ergonomique sur tous les appareils, permettant une planification de convoyage rapide et efficace. Avec notre interface intuitive, vous pouvez réserver un convoyage en moins de 30 secondes. De plus, bénéficiez de rapports complets sur l'enlèvement et la livraison, incluant un état des lieux détaillé. Tous vos convoyages sont archivés avec une multitude de données pour une gestion simplifiée.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Success;
