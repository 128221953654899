// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 15px;
}

.user-details-card {
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.opening-hours {
    margin-top: 20px;
}

.day-menu {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.day-menu .btn-link {
    color: #007bff;
    cursor: pointer;
}

.day-menu .btn-link.active {
    font-weight: bold;
    text-decoration: underline;
}

.day-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.time-slot {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.time-slot label {
    margin-right: 10px;
}

.navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Account.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,uCAAuC;AAC3C;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,uCAAuC;AAC3C;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,0BAA0B;AAC9B;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,uCAAuC;AAC3C;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;AACpB","sourcesContent":[".account-container {\r\n    max-width: 800px;\r\n    margin: 0 auto;\r\n    padding: 20px;\r\n    background-color: #f8f9fa;\r\n    border-radius: 8px;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.form-group {\r\n    margin-bottom: 15px;\r\n}\r\n\r\n.user-details-card {\r\n    background-color: #ffffff;\r\n    padding: 20px;\r\n    margin-bottom: 20px;\r\n    border-radius: 8px;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.opening-hours {\r\n    margin-top: 20px;\r\n}\r\n\r\n.day-menu {\r\n    display: flex;\r\n    justify-content: space-around;\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.day-menu .btn-link {\r\n    color: #007bff;\r\n    cursor: pointer;\r\n}\r\n\r\n.day-menu .btn-link.active {\r\n    font-weight: bold;\r\n    text-decoration: underline;\r\n}\r\n\r\n.day-container {\r\n    background-color: #ffffff;\r\n    padding: 20px;\r\n    border-radius: 8px;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.time-slot {\r\n    display: flex;\r\n    align-items: center;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.time-slot label {\r\n    margin-right: 10px;\r\n}\r\n\r\n.navigation-buttons {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin-top: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
