// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Taille ajustée pour un conteneur plus large et plus haut */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f7f7f7;
}

.login-container.large {
    width: 600px;
    height: 400px;
}

.login-container .card {
    width: 100%;
    border: none;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    position: relative;
    overflow: hidden;
}

/* Animations pour les étapes du formulaire */
.form-step {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    transition: transform 0.5s ease-in-out;
}

.form-step.active {
    left: 0;
    transform: translateX(0);
}

.form-step.exit-left {
    transform: translateX(-100%);
}

.form-step.exit-right {
    transform: translateX(100%);
}
`, "",{"version":3,"sources":["webpack://./src/css/LoginPage.css"],"names":[],"mappings":"AAAA,6DAA6D;AAC7D;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,2CAA2C;IAC3C,aAAa;IACb,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA,6CAA6C;AAC7C;IACI,kBAAkB;IAClB,MAAM;IACN,UAAU;IACV,WAAW;IACX,sCAAsC;AAC1C;;AAEA;IACI,OAAO;IACP,wBAAwB;AAC5B;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":["/* Taille ajustée pour un conteneur plus large et plus haut */\r\n.login-page {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    min-height: 100vh;\r\n    background-color: #f7f7f7;\r\n}\r\n\r\n.login-container.large {\r\n    width: 600px;\r\n    height: 400px;\r\n}\r\n\r\n.login-container .card {\r\n    width: 100%;\r\n    border: none;\r\n    border-radius: 10px;\r\n    background-color: white;\r\n    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);\r\n    padding: 2rem;\r\n    position: relative;\r\n    overflow: hidden;\r\n}\r\n\r\n/* Animations pour les étapes du formulaire */\r\n.form-step {\r\n    position: absolute;\r\n    top: 0;\r\n    left: 100%;\r\n    width: 100%;\r\n    transition: transform 0.5s ease-in-out;\r\n}\r\n\r\n.form-step.active {\r\n    left: 0;\r\n    transform: translateX(0);\r\n}\r\n\r\n.form-step.exit-left {\r\n    transform: translateX(-100%);\r\n}\r\n\r\n.form-step.exit-right {\r\n    transform: translateX(100%);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
