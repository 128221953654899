import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FormCard from '../components/ClientForms/FormCard'; // Assurez-vous que le chemin est correct

function StartedDriverDetail({ driverId }) {
    const [convoyages, setConvoyages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchDriverConvoyages = async () => {
            try {
                const response = await axios.get(`https://ate69.fr:5003/api/convoyages/driver/${driverId}/started`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                setConvoyages(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchDriverConvoyages();
    }, [driverId]);

    if (loading) return <div>Chargement...</div>;
    if (error) return <div>Erreur: {error}</div>;
    if (!convoyages.length) return <div>Aucun convoyage trouvé.</div>;

    return (
        <div>
            <h2>Détails des Convoyages (Démarrés)</h2>
            {convoyages.map((convoyage) => (
                <FormCard 
                    key={convoyage._id} 
                    form={convoyage} 
                    status="started" 
                    hideServiceCost={true} // Masquer le coût du service pour le conducteur
                />
            ))}
        </div>
    );
}

export default StartedDriverDetail;
