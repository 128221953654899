import React from 'react';
import { Helmet } from 'react-helmet';
import { useInView } from 'react-intersection-observer';
import '../centre-gestion.css';

const CentreDeGestion = ({ setTitle, setDescription }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });

  React.useEffect(() => {
    if (inView) {
      setTitle('Centre de Gestion des Convoyages - Lyon, France');
      setDescription('Situé au cœur de Lyon, notre centre de gestion des convoyages se spécialise dans l’organisation sur-mesure de convoyage de véhicules légers.');
    }
  }, [inView, setTitle, setDescription]);

  return (
    <section id="about" className="centre-gestion-section" itemScope itemType="http://schema.org/LocalBusiness" ref={ref}>
      <Helmet>
        <title>Centre de Gestion des Convoyages - Lyon, France</title>
        <meta
          name="description"
          content="Situé au cœur de Lyon, notre centre de gestion des convoyages se spécialise dans l’organisation sur-mesure de convoyage de véhicules légers."
        />
        <meta name="keywords" content="convoyage automobile, centre de gestion, Lyon, transport de véhicules, organisation de convoyage, logistique automobile" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Auto Transport Express" />
        <link rel="canonical" href="https://ate69.fr/centre-gestion" />
        <html lang="fr" />
      </Helmet>

      <div className="centre-gestion-container">
        <div className="centre-gestion-content">
          <h6>Notre mission</h6>
          <h3>Centre de Gestion des Convoyages automobile</h3>
          <p itemProp="description">
            Situé au cœur de Lyon (69), notre centre de gestion des convoyages se spécialise dans l’organisation sur-mesure de convoyage de véhicules légers.
          </p>
          <p>
            À partir de l’interface client que nous proposons, chaque demande est traitée avec une attention particulière. Nous assurons une préparation efficace, en tenant compte des disponibilités de nos clients, et orchestrons avec précision la logistique impliquant le transfert des convoyeurs, la planification des missions, ainsi que l’organisation des circuits de livraison, entre autres aspects cruciaux.
          </p>
          <p>
            Notre centre de gestion agit en véritable chef d’orchestre, assurant une coordination fluide et professionnelle entre nos clients et nos équipes de convoyeurs tout au long du processus.
          </p>

          <div className="centre-gestion-image" itemProp="image">
            <img
              src={require('../../src/img/centre_img.png')}
              alt="image de personnes dans un bureau avec une route et des véhicules"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default CentreDeGestion;
