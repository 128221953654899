import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import UserDetailsModal from './UserDetailsModal';
import '../../css/admin_dash.css';

function AdminDashboard() {
    const navigate = useNavigate();
    const [allDrivers, setAllDrivers] = useState([]);
    const [allAdmins, setAllAdmins] = useState([]);
    const [allClients, setAllClients] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [clients, setClients] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserType, setSelectedUserType] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };

            // Fetch drivers, admins, and clients
            const driversResponse = await axios.get('https://ate69.fr:5002/api/drivers', { headers });
            const adminsResponse = await axios.get('https://ate69.fr:5000/api/admins', { headers });
            const clientsResponse = await axios.get('https://ate69.fr:5001/api/clients', { headers });

            // Log responses for debugging
            console.log('Drivers:', driversResponse.data);
            console.log('Admins:', adminsResponse.data);
            console.log('Clients:', clientsResponse.data);

            setAllDrivers(driversResponse.data);
            setAllAdmins(adminsResponse.data);
            setAllClients(clientsResponse.data);

            // Display the last 5 registered users
            setDrivers(driversResponse.data.slice(-5));
            setAdmins(adminsResponse.data.slice(-5));
            setClients(clientsResponse.data.slice(-5));
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    const updateStatus = async (userType, customIdX, newStatus) => {
        const updateUrl = {
            client: `https://ate69.fr:5001/api/clients/${newStatus}/${customIdX}`,
            driver: `https://ate69.fr:5002/api/drivers/${newStatus}/${customIdX}`,
            admin: `https://ate69.fr:5000/api/admins/${newStatus}/${customIdX}`,
        };

        try {
            const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
            const response = await axios.post(updateUrl[userType], {}, { headers });
            if (response.status === 200) {
                alert(`Account successfully updated to ${newStatus}!`);
                fetchData();
            }
        } catch (error) {
            console.error('Error updating status:', error);
            alert('Error updating status');
        }
    };

    const handleDelete = async (userType, customIdX) => {
        const deleteUrl = {
            client: `https://ate69.fr:5001/api/clients/${customIdX}`,
            driver: `https://ate69.fr:5002/api/drivers/${customIdX}`,
            admin: `https://ate69.fr:5000/api/admins/${customIdX}`,
        };

        try {
            const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
            const response = await axios.delete(deleteUrl[userType], { headers });
            if (response.status === 200) {
                alert('Account successfully deleted!');
                fetchData();
            }
        } catch (error) {
            console.error('Error deleting account:', error);
            alert('Error deleting account');
        }
    };

    const handleViewDetails = (user, type) => {
        setSelectedUser(user);
        setSelectedUserType(type);
        setIsModalOpen(true);
    };

    const handleSearch = (searchTerm, originalUsers, setUserFunc) => {
        const searchTermLower = searchTerm.toLowerCase();
        const filtered = originalUsers.filter(user =>
            (user.name && user.name.toLowerCase().includes(searchTermLower)) ||
            (user.surname && user.surname.toLowerCase().includes(searchTermLower)) ||
            (user.email && user.email.toLowerCase().includes(searchTermLower)) ||
            (user.telephone && user.telephone.toString().includes(searchTermLower)) ||
            (user.mobile && user.mobile.toString().includes(searchTermLower)) ||
            (user.companyName && user.companyName.toLowerCase().includes(searchTermLower)) ||
            (user.siret && user.siret.toString().includes(searchTermLower))
        );
        setUserFunc(filtered);
    };

    if (isLoading) return <div>Loading...</div>;

    return (
        <div className="dashboard">
            <header className="dashboard-header">
                <h1>Admin Dashboard</h1>
                <button onClick={handleLogout} className="logout-btn">Logout</button>
            </header>

            <div className="cards-container">
                <UserCard 
                    title="Clients" 
                    users={clients} 
                    userType="client" 
                    updateStatus={updateStatus} 
                    handleViewDetails={handleViewDetails} 
                    handleDelete={handleDelete} 
                    setSearchTerm={(term) => handleSearch(term, allClients, setClients)} 
                />
                <UserCard 
                    title="Drivers" 
                    users={drivers} 
                    userType="driver" 
                    updateStatus={updateStatus} 
                    handleViewDetails={handleViewDetails} 
                    handleDelete={handleDelete} 
                    setSearchTerm={(term) => handleSearch(term, allDrivers, setDrivers)} 
                />
                <UserCard 
                    title="Admins" 
                    users={admins} 
                    userType="admin" 
                    updateStatus={updateStatus} 
                    handleViewDetails={handleViewDetails} 
                    handleDelete={handleDelete} 
                    setSearchTerm={(term) => handleSearch(term, allAdmins, setAdmins)} 
                />
            </div>

            {isModalOpen && (
                <UserDetailsModal 
                    isOpen={isModalOpen} 
                    user={selectedUser} 
                    userType={selectedUserType} 
                    onClose={() => setIsModalOpen(false)} 
                />
            )}
        </div>
    );
};

const UserCard = ({ title, users, userType, updateStatus, handleViewDetails, handleDelete, setSearchTerm }) => {
    return (
        <div className="user-card">
            <h2>{title}</h2>
            <input 
                type="text" 
                placeholder={`Search in ${title.toLowerCase()}`} 
                onChange={(e) => setSearchTerm(e.target.value)} 
            />
            <div className="user-table">
                {users.map(user => (
                    <div 
                        key={user.customIdC || user.customIdD || user.customIdA} 
                        className="user-row"
                    >
                        <div className="user-info">
                            <p>ID: {user.customIdC || user.customIdD || user.customIdA}</p>
                            <p>Name: {user.name} {user.surname}</p>
                            <p>Email: {user.email}</p>
                            <p>Status: {user.status}</p>
                        </div>
                        <div className="user-actions">
                            <button 
                                onClick={() => updateStatus(userType, user.customIdC || user.customIdD || user.customIdA, 'approve')}
                            >
                                Approve
                            </button>
                            <button 
                                onClick={() => updateStatus(userType, user.customIdC || user.customIdD || user.customIdA, 'reject')}
                            >
                                Reject
                            </button>
                            <button 
                                onClick={() => updateStatus(userType, user.customIdC || user.customIdD || user.customIdA, 'waiting')}
                            >
                                Waiting
                            </button>
                            <button 
                                onClick={() => handleViewDetails(user, userType)}
                            >
                                View Details
                            </button>
                            <button 
                                onClick={() => handleDelete(userType, user.customIdC || user.customIdD || user.customIdA)}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdminDashboard;
