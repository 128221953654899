import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import '../centre-gestion.css';

const Privacy = () => {
    return (
        <div>
            <Helmet>
                <title>Politique de Confidentialité - ATE69</title>
                <meta name="description" content="Politique de confidentialité de ATE69." />
                <link rel="canonical" href="https://ate69.fr/privacy" />
                <html lang="fr" />
            </Helmet>
            <Header />
            <section className="centre-gestion-section">
                <div className="centre-gestion-container">
                    <div className="centre-gestion-content">
                        <h3>Politique de Confidentialité</h3>
                        <p>
                            Bienvenue sur la page de la politique de confidentialité de ATE69. Nous attachons une grande importance à la protection de vos données personnelles et nous nous engageons à respecter votre vie privée. Cette politique de confidentialité décrit les types de données que nous collectons, comment nous les utilisons et les protégeons.
                        </p>

                        <h4>1. Collecte des Informations</h4>
                        <p>
                            Nous collectons diverses informations lorsque vous utilisez nos services, y compris mais sans s'y limiter :
                        </p>
                        <ul>
                            <li>Les informations que vous fournissez directement, telles que votre nom, votre adresse e-mail, votre adresse postale et votre numéro de téléphone.</li>
                            <li>Les informations sur l'utilisation de nos services, telles que les données de connexion, les adresses IP, les données de navigation, etc.</li>
                        </ul>

                        <h4>2. Utilisation des Informations</h4>
                        <p>
                            Les informations que nous collectons sont utilisées pour :
                        </p>
                        <ul>
                            <li>Fournir, exploiter et améliorer nos services.</li>
                            <li>Vous contacter concernant vos demandes et nos services.</li>
                            <li>Personnaliser votre expérience sur notre site.</li>
                            <li>Respecter nos obligations légales et réglementaires.</li>
                        </ul>

                        <h4>3. Partage des Informations</h4>
                        <p>
                            Nous ne vendons pas, n'échangeons pas et ne louons pas vos informations personnelles à des tiers sans votre consentement, sauf dans les cas suivants :
                        </p>
                        <ul>
                            <li>Pour fournir nos services, nous pouvons partager vos informations avec des prestataires de services tiers qui nous assistent dans nos opérations.</li>
                            <li>Lorsque cela est nécessaire pour se conformer à la loi ou répondre à des procédures judiciaires.</li>
                        </ul>

                        <h4>4. Sécurité des Données</h4>
                        <p>
                            Nous mettons en œuvre des mesures de sécurité pour protéger vos informations contre tout accès non autorisé, altération, divulgation ou destruction de vos données personnelles.
                        </p>

                        <h4>5. Droits des Utilisateurs</h4>
                        <p>
                            Vous avez le droit d'accéder à vos données personnelles, de les corriger, de les supprimer, de retirer votre consentement à tout moment et de vous opposer au traitement de vos données personnelles. Pour exercer ces droits, veuillez nous contacter à l'adresse suivante : ate69005@gmail.com.
                        </p>

                        <h4>6. Cookies et Technologies de Suivi</h4>
                        <p>
                            Nous utilisons des cookies et d'autres technologies de suivi pour améliorer votre expérience sur notre site, analyser notre trafic et comprendre d'où viennent nos visiteurs.
                        </p>

                        <h4>7. Modifications de la Politique de Confidentialité</h4>
                        <p>
                            Nous nous réservons le droit de mettre à jour cette politique de confidentialité à tout moment. Nous vous informerons de tout changement en publiant la nouvelle politique de confidentialité sur cette page.
                        </p>

                        <h4>8. Contactez-nous</h4>
                        <p>
                            Si vous avez des questions concernant cette politique de confidentialité, vous pouvez nous contacter par e-mail à ate69005@gmail.com ou par courrier à l'adresse suivante : 21 Quai Fulchiron, 69005 LYON, France.
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Privacy;
