import React from 'react';
import Slider from 'react-slick';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../Hero.css';

const CustomArrow = ({ className, style, onClick, icon }) => (
  <div
    className={className}
    style={{
      ...style,
      display: 'block',
      background: 'rgba(0, 0, 0, 0.5)',
      borderRadius: '50%',
      padding: '10px',
      cursor: 'pointer',
    }}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={icon} size="lg" color="#FFFFFF" />
  </div>
);

const Hero_C = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    nextArrow: <CustomArrow icon={faChevronRight} />,
    prevArrow: <CustomArrow icon={faChevronLeft} />,
  };

  return (
    <section className="hero">
      <Helmet>
        <title>Créez Votre Compte ATE69</title>
        <meta
          name="description"
          content="Créez votre compte ATE69 en moins d'une minute et profitez de nos services de transport automobile."
        />
        <meta
          name="keywords"
          content="convoyage automobile, inscription, ATE69, transport voiture"
        />
        <link rel="canonical" href="https://ate69.fr/inscription" />
      </Helmet>
      <div className="container">
        <Slider {...settings}>
          {[
            {
              title: 'Créez votre compte ATE69',
              description:
                'Profitez de tous les avantages de nos services de transport automobile.',
            },
            {
              title: 'Cela vous prendra moins de 1 minute',
              description:
                'Inscrivez-vous rapidement et facilement pour accéder à nos services.',
            },
            {
              title: 'Profitez des services ATE69',
              description:
                'Des solutions de transport adaptées à tous vos besoins.',
            },
          ].map((slide, index) => (
            <div key={index} className="card">
              <h3>{slide.title}</h3>
              <p>{slide.description}</p>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Hero_C;
