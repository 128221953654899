import React from 'react';
import axios from './axiosConfig'; // Importez la configuration Axios

function EmailAccountList({ accounts, setAccounts, editAccount }) {
    const deleteAccount = async (id) => {
        try {
            await axios.delete(`/accounts/${id}`);
            setAccounts(prev => prev.filter(account => account._id !== id));
        } catch (error) {
            console.error('Failed to delete account', error);
        }
    };

    return (
        <div>
            {accounts.map(account => (
                <div key={account._id}>
                    <span>{account.emailAddress}</span>
                    <button onClick={() => editAccount(account)}>Edit</button>
                    <button onClick={() => deleteAccount(account._id)}>Delete</button>
                </div>
            ))}
        </div>
    );
}

export default EmailAccountList;
