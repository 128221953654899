import React, { useEffect, useRef } from 'react';

const Map = ({ origin, destination }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    const scriptId = 'google-maps-places-script';
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    if (!apiKey) {
      console.error('Google Maps API key is missing');
      return;
    }

    const loadScript = () => {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => {
        initMap();
      };

      script.onerror = () => {
        console.error('Failed to load the Google Maps script.');
      };
    };

    const initMap = () => {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: 48.8566, lng: 2.3522 },
        zoom: 7,
      });

      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer();

      directionsRenderer.setMap(map);

      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    };

    if (!window.google && !document.getElementById(scriptId)) {
      loadScript();
    } else if (window.google && window.google.maps) {
      initMap();
    }
  }, [origin, destination]);

  return <div ref={mapRef} style={{ width: '100%', height: '250px' }} />;
};

export default Map;
