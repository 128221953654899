// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DriverAssignDetail_card__CQlMW {
    display: flex;
    flex-direction: column; /* Changement de la direction de la disposition pour les appareils mobiles */
    padding: 15px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: #f5f5f5;
    color: #333;
    position: relative;
}

.DriverAssignDetail_leftPanel__BncVD,
.DriverAssignDetail_rightPanel__SQkd5 {
    flex: 1 1;
}

.DriverAssignDetail_dateTime__n45uS {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.DriverAssignDetail_address__plSRK {
    margin-bottom: 15px;
}

.DriverAssignDetail_details__Mx3iN div {
    margin-bottom: 10px;
}

/* Media query pour les appareils mobiles jusqu'à une largeur maximale de 767px */
@media only screen and (max-width: 767px) {
    .DriverAssignDetail_card__CQlMW {
        width: calc(100% - 20px); /* Ajustement de la largeur pour s'adapter à l'écran */
    }

    .DriverAssignDetail_infoPanel__lZPQh,
    .DriverAssignDetail_detailsPanel__XwXqy {
        width: 100%; /* Largeur maximale pour s'adapter à l'écran */
    }
}
`, "",{"version":3,"sources":["webpack://./src/DriverAssignDetail.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB,EAAE,4EAA4E;IACpG,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,wCAAwC;IACxC,yBAAyB;IACzB,WAAW;IACX,kBAAkB;AACtB;;AAEA;;IAEI,SAAO;AACX;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA,iFAAiF;AACjF;IACI;QACI,wBAAwB,EAAE,sDAAsD;IACpF;;IAEA;;QAEI,WAAW,EAAE,8CAA8C;IAC/D;AACJ","sourcesContent":[".card {\r\n    display: flex;\r\n    flex-direction: column; /* Changement de la direction de la disposition pour les appareils mobiles */\r\n    padding: 15px;\r\n    margin: 10px;\r\n    border-radius: 8px;\r\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\r\n    background-color: #f5f5f5;\r\n    color: #333;\r\n    position: relative;\r\n}\r\n\r\n.leftPanel,\r\n.rightPanel {\r\n    flex: 1;\r\n}\r\n\r\n.dateTime {\r\n    font-size: 18px;\r\n    font-weight: bold;\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.address {\r\n    margin-bottom: 15px;\r\n}\r\n\r\n.details div {\r\n    margin-bottom: 10px;\r\n}\r\n\r\n/* Media query pour les appareils mobiles jusqu'à une largeur maximale de 767px */\r\n@media only screen and (max-width: 767px) {\r\n    .card {\r\n        width: calc(100% - 20px); /* Ajustement de la largeur pour s'adapter à l'écran */\r\n    }\r\n\r\n    .infoPanel,\r\n    .detailsPanel {\r\n        width: 100%; /* Largeur maximale pour s'adapter à l'écran */\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `DriverAssignDetail_card__CQlMW`,
	"leftPanel": `DriverAssignDetail_leftPanel__BncVD`,
	"rightPanel": `DriverAssignDetail_rightPanel__SQkd5`,
	"dateTime": `DriverAssignDetail_dateTime__n45uS`,
	"address": `DriverAssignDetail_address__plSRK`,
	"details": `DriverAssignDetail_details__Mx3iN`,
	"infoPanel": `DriverAssignDetail_infoPanel__lZPQh`,
	"detailsPanel": `DriverAssignDetail_detailsPanel__XwXqy`
};
export default ___CSS_LOADER_EXPORT___;
