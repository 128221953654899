import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/LoginPage.css'; // Le fichier CSS pour les animations

function LoginPage() {
    const [formData, setFormData] = useState({
        role: '',
        email: '',
        password: '',
    });
    const [step, setStep] = useState(1); // Gestion des étapes du formulaire
    const [isForgotPassword, setIsForgotPassword] = useState(false); // Mode réinitialisation
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(''); // Ajout de l'état successMessage
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setError(null); // Réinitialise les erreurs
    };

    // Gestion des étapes et simulation de bouton "Suivant"
    const handleNextStep = () => {
        if (step === 1 && !formData.role) {
            setError('Veuillez sélectionner un rôle.');
            return;
        } else if (step === 2 && !formData.email) {
            setError('Veuillez entrer votre email.');
            return;
        }
        setError(null);
        setStep(step + 1);
    };

    // Soumission du formulaire de connexion
    const handleLoginSubmit = async (e) => {
        e.preventDefault();

        if (!formData.password) {
            setError('Veuillez entrer votre mot de passe.');
            return;
        }

        setLoading(true);
        setError(null);

        let url;
        switch (formData.role) {
            case 'client':
                url = 'https://ate69.fr:5001/api/clients/login';
                break;
            case 'driver':
                url = 'https://ate69.fr:5002/api/drivers/login';
                break;
            case 'admin':
                url = 'https://ate69.fr:5000/api/admins/login';
                break;
            default:
                setError('Rôle non défini');
                setLoading(false);
                return;
        }

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: formData.email, password: formData.password }),
            });

            const data = await response.json();

            if (response.ok) {
                // Vérifier le statut du client avant d'autoriser la connexion
                if (data.status === 'waiting') {
                    setError('Votre compte est en cours de validation. Veuillez patienter.');
                    navigate('/validation-wait');
                    return;
                } else if (data.status === 'rejected') {
                    setError('Votre compte a été rejeté. Veuillez contacter l\'administration.');
                    return;
                }

                // Stocker le token et rediriger vers le tableau de bord approprié
                localStorage.setItem('token', data.token);
                const userData = { role: formData.role, ...data };
                localStorage.setItem('user', JSON.stringify(userData));

                switch (formData.role) {
                    case 'admin':
                        navigate('/admin/dashboard');
                        break;
                    case 'client':
                        navigate('/client/dashboard');
                        break;
                    case 'driver':
                        navigate('/driver/dashboard');
                        break;
                    default:
                        navigate('/');
                        break;
                }
            } else {
                setError(`Échec de la connexion: ${data.message}`);
            }
        } catch (error) {
            console.error('Erreur lors de la connexion:', error);
            setError("Erreur lors de la connexion au serveur.");
        } finally {
            setLoading(false);
        }
    };

    // Soumission pour la réinitialisation du mot de passe
    const handleForgotPasswordSubmit = async (e) => {
        e.preventDefault();

        if (!formData.email) {
            setError('Veuillez entrer votre email.');
            return;
        }

        setLoading(true);
        setError(null);

        let url;
        switch (formData.role) {
            case 'client':
                url = 'https://ate69.fr:5001/api/clients/forgot-password';
                break;
            case 'driver':
                url = 'https://ate69.fr:5002/api/drivers/forgot-password';
                break;
            case 'admin':
                url = 'https://ate69.fr:5000/api/admins/forgot-password';
                break;
            default:
                setError('Rôle non défini');
                setLoading(false);
                return;
        }

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: formData.email }),
            });

            const data = await response.json();

            if (response.ok) {
                setSuccessMessage('Un email de réinitialisation de mot de passe a été envoyé.');
            } else {
                setError(`Échec de l'envoi: ${data.message}`);
            }
        } catch (error) {
            console.error('Erreur lors de la réinitialisation du mot de passe:', error);
            setError("Erreur lors de l'envoi de l'email de réinitialisation.");
        } finally {
            setLoading(false);
        }
    };

    // Changement de mode (connexion/réinitialisation)
    const toggleForgotPassword = () => {
        setIsForgotPassword(!isForgotPassword);
        setStep(1); // Remet à la première étape
        setError(null); // Réinitialiser les erreurs
        setSuccessMessage(''); // Réinitialiser les messages de succès
    };

    return (
        <div className="login-page">
            <div className="login-container large">
                <div className="card p-4">
                    <h2>{isForgotPassword ? 'Réinitialisation du mot de passe' : 'Connexion'}</h2>
                    <form onSubmit={isForgotPassword ? handleForgotPasswordSubmit : handleLoginSubmit}>
                        {/* Étape 1: Sélection du rôle */}
                        {step === 1 && (
                            <div className="form-group">
                                <label>Rôle:</label>
                                <select
                                    name="role"
                                    value={formData.role}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                >
                                    <option value="">Sélectionnez un rôle</option>
                                    <option value="client">Client</option>
                                    <option value="driver">Conducteur</option>
                                    <option value="admin">Admin</option>
                                </select>
                                <button type="button" className="btn btn-primary w-100 mt-3" onClick={handleNextStep}>
                                    Suivant
                                </button>
                            </div>
                        )}

                        {/* Étape 2: Email */}
                        {step === 2 && (
                            <div className="form-group">
                                <label>Email:</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                                <button type="button" className="btn btn-primary w-100 mt-3" onClick={handleNextStep}>
                                    Suivant
                                </button>
                            </div>
                        )}

                        {/* Étape 3: Mot de passe (uniquement pour la connexion) */}
                        {!isForgotPassword && step === 3 && (
                            <div className="form-group">
                                <label>Mot de passe:</label>
                                <input
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                                <button type="submit" className="btn btn-primary w-100 mt-3" disabled={loading}>
                                    {loading ? 'Chargement...' : 'Envoyer'}
                                </button>
                            </div>
                        )}

                        {/* Mot de passe oublié */}
                        {isForgotPassword && step === 2 && (
                            <div className="mt-3 text-center">
                                <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                                    {loading ? 'Chargement...' : 'Envoyer le lien'}
                                </button>
                            </div>
                        )}

                        {error && <div className="alert alert-danger mt-3">{error}</div>}
                        {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
                    </form>

                    <div className="text-left mt-3">
                        <button
                            className="btn btn-link text-muted"
                            onClick={toggleForgotPassword}
                        >
                            {isForgotPassword ? 'Retour à la connexion' : 'Mot de passe oublié ?'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
