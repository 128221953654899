import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import QuelquesChiffres from './QuelquesChiffres';
import Partners from './Partners';
import '../centre-gestion.css'; // Utiliser les mêmes styles que CentreGestion

const Regulier = () => {
  return (
    <div>
      <Helmet>
        <title>Convoyages Réguliers - ATE69</title>
        <meta name="description" content="Besoin de convoyages réguliers pour votre flotte de véhicules ? Notre service de convoyage régulier est parfait pour vous. Profitez de la flexibilité de notre offre pour transporter vos véhicules en toute sécurité." />
        <link rel="canonical" href="https://ate69.fr/regulier" />
        <html lang="fr" />
      </Helmet>
      <Header />
      <section id="regulier" className="centre-gestion-section" itemScope itemType="http://schema.org/Service">
        <div className="centre-gestion-container">
          <div className="centre-gestion-content">
            <h6>Convoyages Réguliers</h6>
            <h3>Notre service de convoyage régulier</h3>
            <p itemProp="description">
              Besoin de convoyages réguliers pour votre flotte de véhicules ? Notre service de convoyage régulier est parfait pour vous. Profitez de la flexibilité de notre offre pour transporter vos véhicules en toute sécurité.
            </p>
            <p>
              Que vous ayez besoin de convoyages réguliers pour votre entreprise ou votre flotte de véhicules, notre service de convoyage régulier est conçu pour répondre à vos besoins spécifiques. Nous comprenons que chaque déplacement est unique et exige une attention particulière.
            </p>
            <p>
              Grâce à notre expertise et notre expérience, nous vous assurons une solution de transport fiable et sécurisée. Nos convoyeurs professionnels prennent en charge vos véhicules avec le plus grand soin, de la prise en charge à la livraison. Nous nous engageons à respecter les délais convenus et à vous fournir un service de qualité.
            </p>
            <p>
              Profitez de la flexibilité de notre offre pour transporter vos véhicules en toute tranquillité. Que ce soit pour des déplacements réguliers ou pour des besoins spécifiques de votre flotte, nous nous adaptons à vos contraintes et à vos exigences.
            </p>
            <p>
              Notre service de convoyage régulier est parfait pour les entreprises et les professionnels souhaitant une solution de transport sur-mesure, sans les contraintes d’un engagement à long terme. Faites confiance à ATE69 pour tous vos besoins de convoyage automobile régulier.
            </p>
            <p>
              Notre service de convoyage régulier est réservé à ceux ayant réalisé au moins 10 convoyages chez nous. Il est dédié aux concessions, parcs auto, revendeurs, importateurs/exportateurs, loueurs automobiles, fabricants/préparateurs, transporteurs, assurances et leasings.
            </p>
            <p>
              Nous offrons une gestion des livraisons totale : planification avec le service vente/préparation, contact avec le destinataire pour la livraison, gestion des documents tels que Cerfa de cession, mandat et demande de carte grise, dégagement et financement, etc.
            </p>
            <p>
              Les clients réguliers peuvent également accéder à nos services de livraisons à domicile (L.A.D) sur simple demande. Nous proposons une gestion sans contrainte, sans aucun quotas, et une gestion simplifiée des paiements à 30 jours.
            </p>
            <p>
              En outre, nos clients réguliers ont accès à notre programme de parrainage et reçoivent des informations exclusives via notre newsletter. Ces avantages sont sous réserve d'acceptation.
            </p>

            <div className="centre-gestion-image" itemProp="image">
              <img
                src={require('../../src/img/img_card1.jpg')}
                alt="Image représentant le service de convoyage régulier"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="regulier-details" className="work-section">
        <div className="work-container">
          <h2>Convoyage Régulier - Les Points Essentiels</h2>
          <p id="sous-titre" itemProp="description">
            Notre service de convoyage régulier vous offre une flexibilité et une sécurité optimales pour tous vos besoins de transport de véhicules. Voici les points clés de notre offre.
          </p>
          <div className="work-row">
		  
            <div className="work-card">
              <img src={require('../../src/img/img_card1.jpg')} alt="LAD" />
              <h3>L.A.D (Livraison à Domicile)</h3>
              <p>La livraison à domicile est un forfait à 149€ TTC tout compris, incluant la livraison finale d'un véhicule neuf ou d'occasion, directement chez l'acquéreur, dans un rayon de 50 km autour de Lyon (69). Ce service comprend une démonstration du véhicule, une mise en main, ainsi que la gestion des documents de cession et interne, avec des forfaits dégressifs selon la quantité.</p>
              <Link to="/lad" className="work-card-button">En savoir plus</Link>
            </div>
			
            <div className="work-card">
              <img src={require('../../src/img/img_card1.jpg')} alt="Options" />
              <h3>Options</h3>
              <p>Nous offrons plusieurs options pour personnaliser votre convoyage : lavage extérieur pour une présentation impeccable, lavage intérieur pour un habitacle propre et accueillant, et livraison avec le plein pour que votre véhicule soit prêt à rouler dès réception. Chaque option est conçue pour améliorer votre expérience et répondre à vos besoins spécifiques.</p>
              <Link to="/options" className="work-card-button">En savoir plus</Link>
            </div>
            
            <div className="work-card">
              <img src={require('../../src/img/img_card1.jpg')} alt="Parrainage et News" />
              <h3>Parrainage et News</h3>
              <p>Le programme de parrainage permet d'obtenir jusqu'à 10% de réduction sur les convoyages traditionnels. Restez informés des bons plans et des prochaines ouvertures des antennes ATE en France, et bénéficiez de promotions temporaires pour des convoyages intra-antenne grâce à notre newsletter.</p>
              <Link to="/parrainage" className="work-card-button">En savoir plus</Link>
            </div>
          </div>
        </div>
      </section>

      <QuelquesChiffres />
      <Partners />
      <Footer />
    </div>
  );
}

export default Regulier;
