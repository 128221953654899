import React from 'react';
import Slider from 'react-slick';
import { Helmet } from 'react-helmet';
import { useInView } from 'react-intersection-observer';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import '../Hero.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faPlane, faFileAlt, faMoneyBillWave, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const CustomArrow = ({ className, style, onClick, icon }) => (
  <div
    className={className}
    style={{ ...style, display: "block", background: "rgba(0, 0, 0, 0.5)", borderRadius: "50%", padding: "10px", cursor: "pointer" }}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={icon} size="lg" color="#FFFFFF" />
  </div>
);

const Hero = ({ setTitle, setDescription }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });

  React.useEffect(() => {
    if (inView) {
      setTitle('Convoyage Automobile - Auto Transport Express');
      setDescription('Découvrez nos services de convoyage automobile rapide et fiable, disponibles partout en France et à l\'international. Profitez d\'une solution de transport efficace et économique.');
    }
  }, [inView, setTitle, setDescription]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    nextArrow: <CustomArrow icon={faChevronRight} />,
    prevArrow: <CustomArrow icon={faChevronLeft} />,
  };

  return (
    <section className="hero" ref={ref}>
      <Helmet>
        <title>Convoyage Automobile - Auto Transport Express</title>
        <meta name="description" content="Découvrez nos services de convoyage automobile rapide et fiable, disponibles partout en France et à l'international. Profitez d'une solution de transport efficace et économique." />
        <meta name="keywords" content="convoyage automobile, transport de véhicules, service de convoyage, transport rapide, transport international, transport France, Auto Transport Express" />
        <link rel="canonical" href="https://ate69.fr/convoyage-automobile" />
      </Helmet>
      <div className="container">
        <Slider {...settings}>
          {[
            { icon: faCar, title: "Convoyage automobile", description: "Service rapide de transport de véhicules d'un point A à un point B, partout en France et à l'international." },
            { icon: faPlane, title: "Rapidité", description: "Nos services sont exécutés sous 48 heures, garantissant un transport efficace et sans tracas." },
            { icon: faFileAlt, title: "Facilité", description: "Réservez votre convoyage en quelques clics via notre plateforme en ligne intuitive." },
            { icon: faMoneyBillWave, title: "Économique", description: "Profitez d'une alternative rentable au transport traditionnel, avec des tarifs compétitifs." }
          ].map((slide, index) => (
            <div key={index} className="card" itemScope itemType="http://schema.org/Service">
              <FontAwesomeIcon icon={slide.icon} size="3x" color="#31BAF0" />
              <h3 itemProp="name">{slide.title}</h3>
              <p itemProp="description">{slide.description}</p>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Hero;
