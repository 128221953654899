// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card.p-4.mb-4 {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

h2 {
    text-align: center;
}

.form-control {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker {
    width: 80%;
}

.react-datepicker__month-container {
	    width: 100%;

	
}`, "",{"version":3,"sources":["webpack://./src/css/ConvoyageForm.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;KACK,WAAW;;;AAGhB","sourcesContent":[".card.p-4.mb-4 {\r\n    width: 50%;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n}\r\n\r\nh2 {\r\n    text-align: center;\r\n}\r\n\r\n.form-control {\r\n    width: 80%;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n}\r\n\r\n.react-datepicker {\r\n    width: 80%;\r\n}\r\n\r\n.react-datepicker__month-container {\r\n\t    width: 100%;\r\n\r\n\t\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
