import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCar, faCogs } from '@fortawesome/free-solid-svg-icons';
import '../css/register.css';

const Register = () => {
    const navigate = useNavigate();

    return (
        <div className="form-container">
            <p className="info-message">
                Site en cours de développement, les inscriptions sont réservées en interne. Pour toute demande, veuillez contacter le service concerné.
            </p>
            <h2>Choisissez votre rôle</h2>
            <div className="role-selector">
                <div onClick={() => navigate('/register/client')}>
                    <FontAwesomeIcon icon={faUser} />
                    <span>Client</span>
                </div>
                <div onClick={() => navigate('/register/driver')}>
                    <FontAwesomeIcon icon={faCar} />
                    <span>Driver</span>
                </div>
                <div onClick={() => navigate('/register/admin')}>
                    <FontAwesomeIcon icon={faCogs} />
                    <span>Admin</span>
                </div>
            </div>
        </div>
    );
};

export default Register;
