import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import CustomNavbar from './components/CustomNavbar';
// REGISTER 
import RegisterPage from './pages/Register';
import ClientRegister from './pages/client/Client_Register';
import DriverRegister from './pages/driver/Driver_Register';
import AdminRegister from './pages/admin/Admin_Register';

import HomePage from './pages/HomePage';
import ValidationWaitPage from './pages/ValidationWaitPage';
import LoginPage from './pages/LoginPage';
import ResetPassword from './pages/ResetPassword';

import ProtectedRoute from './components/ProtectedRoute';
import AdminDashboard from './pages/admin/AdminDashboard';
import ClientDashboard from './pages/client/ClientDashboard';
import DriverDashboard from './pages/driver/DriverDashboard';
import LogoutPage from './pages/LogoutPage';
import AdminUser from './pages/admin/AdminUsers';
import Account from './components/Account';
import AdminForms from './components/AdminForms/AdminForms';
import AdminFinancial from './pages/admin/AdminFinancial';
import AdminBookDriver from './components/AdminBookDriver';
import DriverAssignDetails from './components/DriverAssignDetail';
import DriverCheckForm from './components/DriverCheckForm';
import DriverCheckFormDel from './components/DriverCheckFormDel';
import StartedFormDetail from './components/StartedFormDetail';
import ClosedFormDetail from './components/ClosedFormDetail';
import StartedDriverDetail from './components/StartedDriverDetail';
import ClosedDriverDetail from './components/ClosedDriverDetail';
import DriverFormViewEnl from './components/DriverFormViewEnl';
import DriverFormViewDel from './components/DriverFormViewDel';
import NestorFormClient from './components/Nestor_Form_Client';
import EmailAccountForm from './components/mail/EmailAccountForm';
import EmailAccountList from './components/mail/EmailAccountList';
import FAQ from './pages/FAQ';
import CGV from './pages/CGV';
import Occasionnel from './pages/Occasionnel';
import Regulier from './pages/Regulier';
import NotFoundPage from './pages/NotFoundPage';
import Privacy from './pages/Privacy'; 
import FinalizeForm from './pages/admin/FinalizeForm';
import FinancialAdminDetails from './pages/admin/FinancialAdminDetails'; // Import de FinancialAdminDetails

function App() {
    return (
        <Router>
            <ScrollToTop />
            <CustomNavbar />
            <Routes>
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/register/client" element={<ClientRegister />} />
                <Route path="/register/driver" element={<DriverRegister />} />
                <Route path="/register/admin" element={<AdminRegister />} />
                <Route path="/login" element={<LoginPage />} />
<Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/" element={<HomePage />} />
                <Route path="/ValidationWaitPage" element={<ValidationWaitPage />} />
                <Route path="/logout" element={<LogoutPage />} />

                <Route path="/admin/dashboard" element={
                  <ProtectedRoute role="admin">
                    <AdminDashboard />
                  </ProtectedRoute>
                } />

                <Route path="/admin/users" element={
                  <ProtectedRoute role="admin">
                    <AdminUser />
                  </ProtectedRoute>
                } />

                <Route path="/admin/forms" element={
                  <ProtectedRoute role="admin">
                    <AdminForms />
                  </ProtectedRoute>
                } />
				
                <Route path="/admin/financial" element={
                  <ProtectedRoute role="admin" fonction={["administratif", "technique"]}>
                    <AdminFinancial />
                  </ProtectedRoute>
                } />

                {/* Nouvelle route pour financialAdminDetails */}
                <Route path="/admin/financial-details/:customIdC" element={
                  <ProtectedRoute role="admin" fonction={["administratif", "technique"]}>
                    <FinancialAdminDetails />
                  </ProtectedRoute>
                } />
				
                <Route path="/client/dashboard" element={
                  <ProtectedRoute role="client">
                    <ClientDashboard />
                  </ProtectedRoute>
                } />

                <Route path="/driver/dashboard" element={
                  <ProtectedRoute role="driver">
                    <DriverDashboard />
                  </ProtectedRoute>
                } />

                <Route path="/account" element={
                  <ProtectedRoute>
                    <Account />
                  </ProtectedRoute>
                } />
                
                <Route path="/admin/driverbook" element={
                  <ProtectedRoute role="admin">
                    <AdminBookDriver />
                  </ProtectedRoute>
                } />
                
                <Route path="/driver-assign-details/:customIdF" element={
                  <ProtectedRoute role="driver">
                    <DriverAssignDetails />
                  </ProtectedRoute>
                } />

                <Route path="/driver-check-form/:id" element={
                  <ProtectedRoute role="driver">
                    <DriverCheckForm />
                  </ProtectedRoute>
                } />

                <Route path="/driver-check-form-del/:id" element={
                  <ProtectedRoute role="driver">
                    <DriverCheckFormDel />
                  </ProtectedRoute>
                } />
                
                <Route path="/details/started/:id" element={
                  <ProtectedRoute role="admin">
                    <StartedFormDetail />
                  </ProtectedRoute>
                } />

                <Route path="/details/completed/:id" element={
                  <ProtectedRoute role="admin">
                    <ClosedFormDetail />
                  </ProtectedRoute>
                } />

                <Route path="/details/driver-enl/:customIdF" element={
                  <ProtectedRoute allowedRoles={['driver', 'admin', 'client']}>
                    <DriverFormViewEnl />
                  </ProtectedRoute>
                } />

                <Route path="/details/driver-del/:convoyageId" element={
                  <ProtectedRoute allowedRoles={['driver', 'admin', 'client']}>
                    <DriverFormViewDel />
                  </ProtectedRoute>
                } /> 

                <Route path="/nestor-form" element={
                  <ProtectedRoute role="client">
                    <NestorFormClient />
                  </ProtectedRoute>
                } />

                <Route path="/admin/email-accounts" element={
                  <ProtectedRoute role="admin">
                    <EmailAccountList />
                  </ProtectedRoute>
                } />

                <Route path="/admin/email-account/new" element={
                  <ProtectedRoute role="admin">
                    <EmailAccountForm />
                  </ProtectedRoute>
                } />

                <Route path="/admin/email-account/edit/:id" element={
                  <ProtectedRoute role="admin">
                    <EmailAccountForm isEditing={true} />
                  </ProtectedRoute>
                } />
                
                <Route path="/driver/details/started/:driverId" element={
                  <ProtectedRoute role="driver">
                    <StartedDriverDetail />
                  </ProtectedRoute>
                } />

                <Route path="/driver/details/finished/:driverId" element={
                  <ProtectedRoute role="driver">
                    <ClosedDriverDetail />
                  </ProtectedRoute>
                } />

                <Route path="/driver-form-view-del/:convoyageId" element={
                  <DriverFormViewDel />
                } />

                <Route path="/faq" element={<FAQ />} />
                <Route path="/cgv" element={<CGV />} />
                <Route path="/occasionnel" element={<Occasionnel />} />
                <Route path="/regulier" element={<Regulier />} />
                <Route path="/privacy" element={<Privacy />} /> {/* Ajouter la route Privacy */}
                <Route path="/finalize/:convoyageId" element={<ProtectedRoute role="admin"><FinalizeForm /></ProtectedRoute>} /> {/* Route pour la page de finalisation */}
                {/* Catch-all route for 404 */}
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </Router>
    );
}

export default App;
