import React from 'react';
import { Helmet } from 'react-helmet';
import { useInView } from 'react-intersection-observer';
import '../Partners.css';
import generali from '../../src/img/generali.png';
import aramis from '../../src/img/aramis.jpg';
import stellantis from '../../src/img/stellantis.png';
import cat from '../../src/img/cat.png';
import gca from '../../src/img/gca.png';
import emilfrey from '../../src/img/emilfrey.png';
import total from '../../src/img/total.png';
import vousIci from '../../src/img/vous-ici.jpg';

const partners = [
  { name: 'Generali', link: 'https://www.generali.fr', image: generali },
  { name: 'Aramis', link: 'https://www.aramisauto.com', image: aramis },
  { name: 'Stellantis', link: 'https://www.stellantis.com', image: stellantis },
  { name: 'CAT', link: 'https://www.groupecat.com', image: cat },
  { name: 'GCA', link: 'https://www.charlesandre.com/', image: gca },
  { name: 'EMILFREY', link: 'https://emilfreyfrance.fr/', image: emilfrey },
  { name: 'Total', link: 'https://www.total.com', image: total },
  { name: 'Vous Ici ?', link: '#', image: vousIci }
];

const Partners = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });

  return (
    <section id="partners" className="partners-section" ref={ref}>
      <Helmet>
        <title>Nos Partenaires - ATE69</title>
        <meta name="description" content="Découvrez nos partenaires de confiance avec qui nous travaillons étroitement." />
      </Helmet>
      <h2>Nos Partenaires</h2>
      <div className={`partners-container ${inView ? 'in-view' : ''}`}>
        {partners.map((partner, index) => (
          <div className="partner-card" key={index}>
            <a href={partner.link} target="_blank" rel="noopener noreferrer">
              <img src={partner.image} alt={partner.name} onLoad={() => console.log(`Image loaded: ${partner.name}`)} onError={() => console.error(`Error loading image: ${partner.name}`)} />
            </a>
            <p>{partner.name}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Partners;
