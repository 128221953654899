import React, { useState } from 'react';
import axios from 'axios';
import Estimate from '../../components/Estimate';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faTruckPickup, faTruck, faUser, faUserTie } from '@fortawesome/free-solid-svg-icons';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/ConvoyageForm.css';

function ConvoyageForm({ clientInfo }) {
  const generateCustomIdF = () => {
    const prefix = 'ATE69|F|';
    const uniqueId = Math.random().toString(36).substr(2, 9).toUpperCase();
    return `${prefix}${uniqueId}`;
  };

  const initialState = {
    clientDetails: {
      customIdC: clientInfo?.customIdC || '',
	      siret: clientInfo?.siret || '', // Ajout du SIRET ici
      name: clientInfo?.name || '',
      surname: clientInfo?.surname || '',
      email: clientInfo?.email || '',
      phone: clientInfo?.phone || '',
      company: clientInfo?.companyName || '',
      address: clientInfo?.companyAddress || { streetType: '', streetName: '', number: '', city: '', postalCode: '' }
    },
    vehicleDetails: {
      type: '',
      brand: '',
      model: '',
      registration: '',
      availabilityDate: new Date()
    },
    pickupDetails: {
      name: '',
      surname: '',
      email: '',
      phone: '',
      company: '',
      address: { streetType: '', streetName: '', number: '', city: '', postalCode: '' }
    },
    deliveryDetails: {
      name: '',
      surname: '',
      email: '',
      phone: '',
      company: '',
      address: { streetType: '', streetName: '', number: '', city: '', postalCode: '' }
    },
    pricingDetails: {
      serviceCost: 0,
      tollCostEstimate: 0,
      fuelCostEstimate: 0,
      totalPrice: 0,
      distance: 0, // Distance du trajet
      duration: '00:00', // Durée estimée du trajet
      advancePending: 0,
      finalAmountDue: 0,
      isShortDistance: false
    },
    role: '',
    customIdF: generateCustomIdF()
  };

  const [formData, setFormData] = useState(initialState);
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [calculatedPricing, setCalculatedPricing] = useState({
    serviceCost: 0,
    tolls: 0,
    fuelCost: 0,
    totalPrice: 0,
    distance: 0, // Distance calculée
    duration: '00:00', // Durée calculée
    advancePending: 0,
    finalAmountDue: 0,
    isShortDistance: false
  });
  const [acceptedCGV, setAcceptedCGV] = useState(false);

  const handleNestedChange = (e, section) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [section]: {
        ...prevFormData[section],
        [name]: value
      }
    }));
  };

  const handleAddressChange = (e, section) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [section]: {
        ...prevFormData[section],
        address: {
          ...prevFormData[section].address,
          [name]: value
        }
      }
    }));
  };

  const handleRoleChange = (role) => {
    const updatedFormData = { ...formData, role };

    if (role === 'expéditeur') {
      updatedFormData.deliveryDetails = {
        name: '',
        surname: '',
        email: '',
        phone: '',
        company: '',
        address: { streetType: '', streetName: '', number: '', city: '', postalCode: '' }
      };
      updatedFormData.pickupDetails = {
        name: clientInfo?.name || '',
        surname: clientInfo?.surname || '',
        email: clientInfo?.email || '',
        phone: clientInfo?.phone || '',
        company: clientInfo?.companyName || '',
        address: clientInfo?.companyAddress || { streetType: '', streetName: '', number: '', city: '', postalCode: '' }
      };
    } else if (role === 'destinataire') {
      updatedFormData.pickupDetails = {
        name: '',
        surname: '',
        email: '',
        phone: '',
        company: '',
        address: { streetType: '', streetName: '', number: '', city: '', postalCode: '' }
      };
      updatedFormData.deliveryDetails = {
        name: clientInfo?.name || '',
        surname: clientInfo?.surname || '',
        email: clientInfo?.email || '',
        phone: clientInfo?.phone || '',
        company: clientInfo?.companyName || '',
        address: clientInfo?.companyAddress || { streetType: '', streetName: '', number: '', city: '', postalCode: '' }
      };
    }

    setFormData(updatedFormData);
    setStep(2); // Passe automatiquement à l'étape suivante après la sélection du rôle
  };

  const handleVehicleTypeChange = (type) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      vehicleDetails: {
        ...prevFormData.vehicleDetails,
        type
      }
    }));
    setStep(3); // Passe automatiquement à l'étape suivante après la sélection du type de véhicule
  };

  const handlePricingCalculated = (pricingDetails) => {
    // Extraire la valeur numérique pour la distance
    const distance = parseFloat(pricingDetails.distance.replace(/[^0-9.]/g, ''));

    const advancePending = (pricingDetails.serviceCost / 2) + (pricingDetails.tolls / 2) + (pricingDetails.fuelCost / 2);
    const finalAmountDue = pricingDetails.totalPrice - advancePending;

    setFormData(prevFormData => ({
        ...prevFormData,
        pricingDetails: {
            ...prevFormData.pricingDetails,
            serviceCost: pricingDetails.serviceCost,
            tollCostEstimate: pricingDetails.tolls,
            fuelCostEstimate: pricingDetails.fuelCost,
            totalPrice: pricingDetails.totalPrice,
            distance: distance,  // Utiliser la distance numérique
            duration: pricingDetails.duration,
            isShortDistance: pricingDetails.isShortDistance,
            advancePending: advancePending,
            finalAmountDue: finalAmountDue
        }
    }));
    setCalculatedPricing({ ...pricingDetails, advancePending, finalAmountDue });
};


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!acceptedCGV) {
      setErrors({ cgv: "Vous devez accepter les Conditions Générales de Vente." });
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      };

      await axios.post('https://ate69.fr:5003/api/convoyages', formData, config);
      alert(`Votre demande de convoyage a bien été envoyée.`);
    } catch (error) {
      console.error('Erreur lors de l\'envoi du formulaire:', error.response ? error.response.data : error.message);
    }
  };

  const formatAddress = (address) => {
    if (!address) return 'Adresse non disponible';
    const { streetType, streetName, number, city, postalCode } = address;
    return `${number} ${streetType} ${streetName}, ${postalCode} ${city}`;
  };

  const validateStep = (step) => {
    const { vehicleDetails, pickupDetails, deliveryDetails } = formData;
    let isValid = true;
    const newErrors = {};

    switch (step) {
      case 3:
        if (!vehicleDetails.type) {
          newErrors.type = "Ce champ est obligatoire.";
          isValid = false;
        }
        break;
      case 4:
        if (!vehicleDetails.brand) {
          newErrors.brand = "Ce champ est obligatoire.";
          isValid = false;
        }
        break;
      case 5:
        if (!vehicleDetails.model) {
          newErrors.model = "Ce champ est obligatoire.";
          isValid = false;
        }
        break;
      case 6:
        if (!vehicleDetails.registration) {
          newErrors.registration = "Ce champ est obligatoire.";
          isValid = false;
        }
        break;
      case 7:
        if (!pickupDetails.name && !deliveryDetails.name) {
          newErrors.name = "Ce champ est obligatoire.";
          isValid = false;
        }
        break;
      case 8:
        if (!pickupDetails.surname && !deliveryDetails.surname) {
          newErrors.surname = "Ce champ est obligatoire.";
          isValid = false;
        }
        break;
      case 9:
        if (!pickupDetails.email && !deliveryDetails.email) {
          newErrors.email = "Ce champ est obligatoire.";
          isValid = false;
        }
        break;
      case 10:
        if (!pickupDetails.phone && !deliveryDetails.phone) {
          newErrors.phone = "Ce champ est obligatoire.";
          isValid = false;
        }
        break;
      case 11:
        if (!pickupDetails.company && !deliveryDetails.company) {
          newErrors.company = "Ce champ est obligatoire.";
          isValid = false;
        }
        break;
      case 12:
        if (!pickupDetails.address.number && !deliveryDetails.address.number) {
          newErrors.number = "Ce champ est obligatoire.";
          isValid = false;
        }
        break;
      case 13:
        if (!pickupDetails.address.streetType && !deliveryDetails.address.streetType) {
          newErrors.streetType = "Ce champ est obligatoire.";
          isValid = false;
        }
        break;
      case 14:
        if (!pickupDetails.address.streetName && !deliveryDetails.address.streetName) {
          newErrors.streetName = "Ce champ est obligatoire.";
          isValid = false;
        }
        break;
      case 15:
        if (!pickupDetails.address.postalCode && !deliveryDetails.address.postalCode) {
          newErrors.postalCode = "Ce champ est obligatoire.";
          isValid = false;
        }
        break;
      case 16:
        if (!pickupDetails.address.city && !deliveryDetails.address.city) {
          newErrors.city = "Ce champ est obligatoire.";
          isValid = false;
        }
        break;
      default:
        break;
    }

    setErrors(newErrors);
    return isValid;
  };

  const goToNextStep = () => {
    if (validateStep(step)) {
      setStep(step + 1);
    }
  };

  const goToPreviousStep = () => {
    setStep(step - 1);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      goToNextStep();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {step === 1 && (
        <div className="card p-4 mb-4">
          <label>Je suis :</label>
          <div className="d-flex justify-content-around">
            <div
              className={`card p-3 text-center ${formData.role === 'expéditeur' ? 'bg-primary text-white' : ''}`}
              style={{ cursor: 'pointer' }}
              onClick={() => handleRoleChange('expéditeur')}
            >
              <FontAwesomeIcon icon={faUserTie} size="2x" />
              <p>Expéditeur</p>
            </div>
            <div
              className={`card p-3 text-center ${formData.role === 'destinataire' ? 'bg-primary text-white' : ''}`}
              style={{ cursor: 'pointer' }}
              onClick={() => handleRoleChange('destinataire')}
            >
              <FontAwesomeIcon icon={faUser} size="2x" />
              <p>Destinataire</p>
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="card p-4 mb-4">
          <h3>Type de véhicule</h3>
          <div className="d-flex justify-content-around">
            <div
              className={`card p-3 text-center ${formData.vehicleDetails.type === 'citadine' ? 'bg-primary text-white' : ''}`}
              style={{ cursor: 'pointer' }}
              onClick={() => handleVehicleTypeChange('citadine')}
            >
              <FontAwesomeIcon icon={faCar} size="2x" />
              <p>Citadine</p>
            </div>
            <div
              className={`card p-3 text-center ${formData.vehicleDetails.type === 'berline' ? 'bg-primary text-white' : ''}`}
              style={{ cursor: 'pointer' }}
              onClick={() => handleVehicleTypeChange('berline')}
            >
              <FontAwesomeIcon icon={faTruckPickup} size="2x" />
              <p>Berline</p>
            </div>
            <div
              className={`card p-3 text-center ${formData.vehicleDetails.type === 'utilitaire' ? 'bg-primary text-white' : ''}`}
              style={{ cursor: 'pointer' }}
              onClick={() => handleVehicleTypeChange('utilitaire')}
            >
              <FontAwesomeIcon icon={faTruck} size="2x" />
              <p>Utilitaire</p>
            </div>
          </div>
        </div>
      )}

      {step === 3 && (
        <div className="card p-4 mb-4">
          <label>Marque <span className="text-danger">*</span></label>
          <input
            type="text"
            name="brand"
            className={`form-control ${errors.brand ? 'is-invalid' : ''}`}
            value={formData.vehicleDetails.brand}
            onChange={(e) => handleNestedChange(e, 'vehicleDetails')}
            placeholder="Marque"
            required
            onKeyPress={handleKeyPress}
          />
          {errors.brand && <div className="invalid-feedback">{errors.brand}</div>}
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btn btn-primary" onClick={goToNextStep}>Suivant</button>
          </div>
        </div>
      )}

      {step === 4 && (
        <div className="card p-4 mb-4">
          <label>Modèle <span className="text-danger">*</span></label>
          <input
            type="text"
            name="model"
            className={`form-control ${errors.model ? 'is-invalid' : ''}`}
            value={formData.vehicleDetails.model}
            onChange={(e) => handleNestedChange(e, 'vehicleDetails')}
            placeholder="Modèle"
            required
            onKeyPress={handleKeyPress}
          />
          {errors.model && <div className="invalid-feedback">{errors.model}</div>}
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btn btn-primary" onClick={goToNextStep}>Suivant</button>
          </div>
        </div>
      )}

      {step === 5 && (
        <div className="card p-4 mb-4">
          <label>Immatriculation <span className="text-danger">*</span></label>
          <input
            type="text"
            name="registration"
            className={`form-control ${errors.registration ? 'is-invalid' : ''}`}
            value={formData.vehicleDetails.registration}
            onChange={(e) => handleNestedChange(e, 'vehicleDetails')}
            placeholder="09-AA-1234"
            required
            onKeyPress={handleKeyPress}
          />
          {errors.registration && <div className="invalid-feedback">{errors.registration}</div>}
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btn btn-primary" onClick={goToNextStep}>Suivant</button>
          </div>
        </div>
      )}

      {step === 6 && (
        <div className="card p-4 mb-4">
          <label>Date de disponibilité <span className="text-danger">*</span></label>
          <div className="calendar-container">
            <DatePicker
              selected={formData.vehicleDetails.availabilityDate}
              onChange={(date) => handleNestedChange({ target: { name: 'availabilityDate', value: date } }, 'vehicleDetails')}
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
              inline
              required
            />
          </div>
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btn btn-primary" onClick={goToNextStep}>Suivant</button>
          </div>
        </div>
      )}

      {step === 7 && (
        <div className="card p-4 mb-4">
          <h3>Coordonnées {formData.role === 'destinataire' ? 'de l\'expéditeur' : 'du destinataire'}</h3>
          <label>Nom <span className="text-danger">*</span></label>
          <input
            type="text"
            name="name"
            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
            value={formData[formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails'].name}
            onChange={(e) => handleNestedChange(e, formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails')}
            placeholder="Nom"
            required
            onKeyPress={handleKeyPress}
          />
          {errors.name && <div className="invalid-feedback">{errors.name}</div>}
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btn btn-primary" onClick={goToNextStep}>Suivant</button>
          </div>
        </div>
      )}

      {step === 8 && (
        <div className="card p-4 mb-4">
          <h3>Coordonnées de {formData.role === 'destinataire' ? 'l\'expéditeur' : 'du destinataire'}</h3>
          <label>Prénom <span className="text-danger">*</span></label>
          <input
            type="text"
            name="surname"
            className={`form-control ${errors.surname ? 'is-invalid' : ''}`}
            value={formData[formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails'].surname}
            onChange={(e) => handleNestedChange(e, formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails')}
            placeholder="Prénom"
            required
            onKeyPress={handleKeyPress}
          />
          {errors.surname && <div className="invalid-feedback">{errors.surname}</div>}
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btn btn-primary" onClick={goToNextStep}>Suivant</button>
          </div>
        </div>
      )}

      {step === 9 && (
        <div className="card p-4 mb-4">
          <h3>Coordonnées de {formData.role === 'destinataire' ? 'l\'expéditeur' : 'du destinataire'}</h3>
          <label>Email <span className="text-danger">*</span></label>
          <input
            type="email"
            name="email"
            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
            value={formData[formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails'].email}
            onChange={(e) => handleNestedChange(e, formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails')}
            placeholder="Email"
            required
            onKeyPress={handleKeyPress}
          />
          {errors.email && <div className="invalid-feedback">{errors.email}</div>}
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btn btn-primary" onClick={goToNextStep}>Suivant</button>
          </div>
        </div>
      )}

      {step === 10 && (
        <div className="card p-4 mb-4">
          <h3>Coordonnées de {formData.role === 'destinataire' ? 'l\'expéditeur' : 'du destinataire'}</h3>
          <label>Téléphone <span className="text-danger">*</span></label>
          <input
            type="text"
            name="phone"
            className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
            value={formData[formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails'].phone}
            onChange={(e) => handleNestedChange(e, formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails')}
            placeholder="Téléphone"
            required
            onKeyPress={handleKeyPress}
          />
          {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btn btn-primary" onClick={goToNextStep}>Suivant</button>
          </div>
        </div>
      )}

      {step === 11 && (
        <div className="card p-4 mb-4">
          <h3>Coordonnées de {formData.role === 'destinataire' ? 'l\'expéditeur' : 'du destinataire'}</h3>
          <label>Compagnie <span className="text-danger">*</span></label>
          <input
            type="text"
            name="company"
            className={`form-control ${errors.company ? 'is-invalid' : ''}`}
            value={formData[formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails'].company}
            onChange={(e) => handleNestedChange(e, formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails')}
            placeholder="Compagnie"
            required
            onKeyPress={handleKeyPress}
          />
          {errors.company && <div className="invalid-feedback">{errors.company}</div>}
          <label className="mt-3">
            <input
              type="checkbox"
              name="particulier"
              checked={formData[formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails'].company === 'Particulier'}
              onChange={(e) => handleNestedChange({ target: { name: 'company', value: e.target.checked ? 'Particulier' : '' } }, formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails')}
            />
            Particulier
          </label>
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btn btn-primary" onClick={goToNextStep}>Suivant</button>
          </div>
        </div>
      )}

      {step === 12 && (
        <div className="card p-4 mb-4">
          <h3>Coordonnées de {formData.role === 'destinataire' ? 'l\'expéditeur' : 'du destinataire'}</h3>
          <label>Numéro <span className="text-danger">*</span></label>
          <input
            type="text"
            name="number"
            className={`form-control ${errors.number ? 'is-invalid' : ''}`}
            value={formData[formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails'].address.number}
            onChange={(e) => handleAddressChange(e, formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails')}
            placeholder="Numéro"
            required
            onKeyPress={handleKeyPress}
          />
          {errors.number && <div className="invalid-feedback">{errors.number}</div>}
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btn btn-primary" onClick={goToNextStep}>Suivant</button>
          </div>
        </div>
      )}

      {step === 13 && (
        <div className="card p-4 mb-4">
          <h3>Coordonnées de {formData.role === 'destinataire' ? 'l\'expéditeur' : 'du destinataire'}</h3>
          <label>Type de voie <span className="text-danger">*</span></label>
          <input
            type="text"
            name="streetType"
            className={`form-control ${errors.streetType ? 'is-invalid' : ''}`}
            value={formData[formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails'].address.streetType}
            onChange={(e) => handleAddressChange(e, formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails')}
            placeholder="Type de voie"
            required
            onKeyPress={handleKeyPress}
          />
          {errors.streetType && <div className="invalid-feedback">{errors.streetType}</div>}
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btn btn-primary" onClick={goToNextStep}>Suivant</button>
          </div>
        </div>
      )}

      {step === 14 && (
        <div className="card p-4 mb-4">
          <h3>Coordonnées de {formData.role === 'destinataire' ? 'l\'expéditeur' : 'du destinataire'}</h3>
          <label>Nom de rue <span className="text-danger">*</span></label>
          <input
            type="text"
            name="streetName"
            className={`form-control ${errors.streetName ? 'is-invalid' : ''}`}
            value={formData[formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails'].address.streetName}
            onChange={(e) => handleAddressChange(e, formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails')}
            placeholder="Nom de rue"
            required
            onKeyPress={handleKeyPress}
          />
          {errors.streetName && <div className="invalid-feedback">{errors.streetName}</div>}
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btn btn-primary" onClick={goToNextStep}>Suivant</button>
          </div>
        </div>
      )}

      {step === 15 && (
        <div className="card p-4 mb-4">
          <h3>Coordonnées de {formData.role === 'destinataire' ? 'l\'expéditeur' : 'du destinataire'}</h3>
          <label>Code postal <span className="text-danger">*</span></label>
          <input
            type="text"
            name="postalCode"
            className={`form-control ${errors.postalCode ? 'is-invalid' : ''}`}
            value={formData[formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails'].address.postalCode}
            onChange={(e) => handleAddressChange(e, formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails')}
            placeholder="Code postal"
            required
            onKeyPress={handleKeyPress}
          />
          {errors.postalCode && <div className="invalid-feedback">{errors.postalCode}</div>}
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btn btn-primary" onClick={goToNextStep}>Suivant</button>
          </div>
        </div>
      )}

      {step === 16 && (
        <div className="card p-4 mb-4">
          <h3>Coordonnées de {formData.role === 'destinataire' ? 'l\'expéditeur' : 'du destinataire'}</h3>
          <label>Ville <span className="text-danger">*</span></label>
          <input
            type="text"
            name="city"
            className={`form-control ${errors.city ? 'is-invalid' : ''}`}
            value={formData[formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails'].address.city}
            onChange={(e) => handleAddressChange(e, formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails')}
            placeholder="Ville"
            required
            onKeyPress={handleKeyPress}
          />
          {errors.city && <div className="invalid-feedback">{errors.city}</div>}
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btn btn-primary" onClick={goToNextStep}>Suivant</button>
          </div>
        </div>
      )}

      {step === 17 && (
        <div className="card p-4 mb-4">
          <h3>Détails de tarification</h3>
          <Estimate
            origin={formData.pickupDetails.address ? `${formData.pickupDetails.address.streetType} ${formData.pickupDetails.address.streetName} ${formData.pickupDetails.address.number}, ${formData.pickupDetails.address.city}, ${formData.pickupDetails.address.postalCode}` : 'Adresse de départ indisponible'}
            destination={formData.deliveryDetails.address ? `${formData.deliveryDetails.address.streetType} ${formData.deliveryDetails.address.streetName} ${formData.deliveryDetails.address.number}, ${formData.deliveryDetails.address.city}, ${formData.deliveryDetails.address.postalCode}` : 'Adresse de livraison indisponible'}
            vehicleType={formData.vehicleDetails.type}
            onCalculate={(pricingDetails) => {
              handlePricingCalculated(pricingDetails);
            }}
          />
          <div>
            <h4>Tarifs Calculés :</h4>
            <p>Tarif de la prestation: {calculatedPricing.serviceCost} €</p>
            <p>Péage estimé: {calculatedPricing.tolls} €</p>
            <p>Carburant estimé: {calculatedPricing.fuelCost} €</p>
            <p>Distance estimée: {calculatedPricing.distance} km</p>
            <p>Durée estimée: {calculatedPricing.duration}</p>
            <p>Total estimé: {calculatedPricing.totalPrice} €</p>
            <p><strong>Acompte à verser: {calculatedPricing.advancePending} €</strong></p>
          </div>
          <div className="d-flex justify-content-between mt-4">
            <button type="button" className="btn btn-secondary" onClick={goToPreviousStep}>Précédent</button>
            <button type="button" className="btn btn-primary" onClick={goToNextStep}>Suivant</button>
          </div>
        </div>
      )}

      {step === 18 && (
        <div className="card p-4 mb-4">
          <h3>Résumé du convoyage</h3>
          <div className="row">
            <div className="col-md-4">
              <div>
                <p><strong>Convoyage de :</strong> {formData.vehicleDetails.brand} {formData.vehicleDetails.model} ({formData.vehicleDetails.registration})</p>
                <p><strong>Depuis :</strong> {formatAddress(formData.pickupDetails.address)}</p>
                <p><strong>À :</strong> {formatAddress(formData.deliveryDetails.address)}</p>
                <div>
                  <h4>Tarifs Calculés :</h4>
                  <p>Tarif de la prestation: {calculatedPricing.serviceCost} €</p>
                  {calculatedPricing.isShortDistance && (
                    <div className="alert alert-info" role="alert">
                      Remarque : Pour un trajet de moins de 50 km, la prestation à domicile est proposée à un tarif fixe de 149 €.
                    </div>
                  )}
                  <p>Péage estimé: {calculatedPricing.tolls} €</p>
                  <p>Carburant estimé: {calculatedPricing.fuelCost} €</p>
                  <p>Distance estimée: {calculatedPricing.distance} km</p>
                  <p>Durée estimée: {calculatedPricing.duration}</p>
                  <p>Total estimé: {calculatedPricing.totalPrice} €</p>
                  <p><strong>Acompte à verser: {calculatedPricing.advancePending} €</strong></p>
                </div>
                <p>En soumettant le formulaire, vous vous engagez à réaliser le convoyage du véhicule mentionné plus haut. Des frais d'annulation peuvent être appliqués conformément à nos conditions générales de vente : <a href="https://ate69.fr/cgv">ate69.fr/cgv</a>.</p>
              </div>
            </div>
            <div className="col-md-8">
              <div className="map-container">
                <Estimate
                  origin={formData.pickupDetails.address ? `${formData.pickupDetails.address.streetType} ${formData.pickupDetails.address.streetName} ${formData.pickupDetails.address.number}, ${formData.pickupDetails.address.city}, ${formData.pickupDetails.address.postalCode}` : 'Adresse de départ indisponible'}
                  destination={formData.deliveryDetails.address ? `${formData.deliveryDetails.address.streetType} ${formData.deliveryDetails.address.streetName} ${formData.deliveryDetails.address.number}, ${formData.deliveryDetails.address.city}, ${formData.deliveryDetails.address.postalCode}` : 'Adresse de livraison indisponible'}
                  vehicleType={formData.vehicleDetails.type}
                  onCalculate={(pricingDetails) => {
                    handlePricingCalculated(pricingDetails);
                  }}
                />
              </div>
            </div>
          </div>
          
          {/* Checkbox for CGV Acceptance */}
          <div className="form-group mt-4">
            <input
              type="checkbox"
              id="acceptCGV"
              checked={acceptedCGV}
              onChange={(e) => setAcceptedCGV(e.target.checked)}
              required
            />
            <label htmlFor="acceptCGV" className="ml-2">
              J'accepte les <a href="https://ate69.fr/cgv" target="_blank" rel="noopener noreferrer">Conditions Générales de Vente</a>
            </label>
            {errors.cgv && <div className="text-danger mt-2">{errors.cgv}</div>}
          </div>
          
          <div className="d-flex justify-content-between mt-4">
            <button type="button" className="btn btn-secondary" onClick={goToPreviousStep}>Précédent</button>
            <button type="submit" className="btn btn-success">Soumettre</button>
          </div>
        </div>
      )}

    </form>
  );
}

export default ConvoyageForm;
