import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWave, faRoad, faGasPump, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Map from '../../components/ClientForms/Map';
import FormDetailsModal from '../../components/AdminForms/FormModal';
import FinalizeModal from '../../pages/admin/FinalizeForm';
import styles from '../../css/FormCard.module.css';

const FormCard = ({ form, onFormUpdate }) => {
    const [driverInfo, setDriverInfo] = useState(null);
    const [detailsVisible, setDetailsVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFinalizationModalOpen, setIsFinalizationModalOpen] = useState(false);
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
    const userRole = user ? user.role : '';

    useEffect(() => {
        if (form.assignedDriver?.customIdD) {
            axios.get(`https://ate69.fr:5002/api/drivers/${form.assignedDriver.customIdD}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            .then(response => {
                setDriverInfo(response.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des informations du conducteur:', error);
                setDriverInfo({ name: 'Erreur de chargement', surname: '', status: 'N/A', phone: 'N/A', email: 'N/A' });
            });
        }
    }, [form.assignedDriver]);

    const toggleDetails = () => {
        setDetailsVisible(!detailsVisible);
    };

    const formatDateTime = (dateString) => {
        if (!dateString) {
            return 'Non disponible';
        }
        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleString('fr-FR', options);
    };

    const calculateDuration = (start, end) => {
        const startTime = new Date(start);
        const endTime = new Date(end);
        if (isNaN(startTime) || isNaN(endTime)) {
            return 'N/A';
        }
        const durationMs = endTime - startTime;
        const durationHours = Math.floor(durationMs / (1000 * 60 * 60));
        const durationMinutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
        return `${durationHours}h ${durationMinutes}m`;
    };

    const calculateEstimatedArrival = (startDate, duration) => {
        if (!startDate || !duration) {
            return 'N/A';
        }

        // Vérifier que duration est bien une chaîne de caractères dans le format "hh:mm"
        if (typeof duration === 'string') {
            const [hours, minutes] = duration.split(':').map(Number);
            const estimatedArrival = new Date(new Date(startDate).getTime() + (hours * 60 + minutes) * 60 * 1000);
            return formatDateTime(estimatedArrival);
        }

        return 'Durée invalide';
    };

    const mileageDifference = form.deliveryCheck?.mileage && form.pickupCheck?.mileage 
        ? form.deliveryCheck.mileage - form.pickupCheck.mileage 
        : 'N/A';

    const getTextColor = (estimate, actual, lowerBound = false) => {
        const difference = Math.abs(estimate - actual);
        const percentageDifference = (difference / estimate) * 100;

        if (percentageDifference <= 10) {
            return styles.green;
        } else if (lowerBound && actual < estimate) {
            return styles.red;
        } else if (!lowerBound && actual > estimate) {
            return styles.red;
        }
        return styles.orange;
    };

    const shouldAutoValidate = () => {
        const tollDifference = Math.abs(form.pricingDetails.tollCostEstimate - form.deliveryCheck.peage);
        const fuelDifference = Math.abs(form.pricingDetails.fuelCostEstimate - form.deliveryCheck.carburant);
        const mileageEstimate = parseInt(form.pricingDetails.distance);
        const mileageReal = parseInt(mileageDifference);
        const mileageDiff = Math.abs(mileageEstimate - mileageReal);

        const durationEstimate = form.pricingDetails.duration;
        const durationReal = calculateDuration(form.startedAt, form.completedAt);
        const durationDiff = Math.abs(new Date(`1970-01-01T${durationEstimate}:00Z`) - new Date(`1970-01-01T${durationReal}:00Z`));

        const tollWithinLimit = tollDifference <= form.pricingDetails.tollCostEstimate * 0.10;
        const fuelWithinLimit = fuelDifference <= form.pricingDetails.fuelCostEstimate * 0.10;
        const mileageWithinLimit = mileageDiff <= mileageEstimate * 0.10;
        const durationWithinLimit = durationDiff <= new Date(`1970-01-01T${durationEstimate}:00Z`) * 0.10;

        return tollWithinLimit && fuelWithinLimit && mileageWithinLimit && durationWithinLimit && mileageReal >= mileageEstimate;
    };

    const handleFinalize = async (customIdF, serviceCost, peage, carburant, adminNotes) => {
        try {
            const shouldFinalize = shouldAutoValidate();
            const status = shouldFinalize ? 'finished' : 'terminated';

            await axios.put(`https://ate69.fr:5003/api/convoyages/${customIdF}/finalize`, 
                { serviceCost, peage, carburant, adminNotes, status }, 
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
            );

            if (shouldFinalize) {
                alert('Le convoyage a été automatiquement validé et finalisé.');
            }

            setIsFinalizationModalOpen(false);
            onFormUpdate();
        } catch (error) {
            console.error('Erreur lors de la finalisation du convoyage:', error);
        }
    };

const renderPricingDetails = () => {
    if (userRole === 'client' || userRole === 'admin') {
        if (form.status === 'terminated' || form.status === 'finished') {
            return (
                <div className={styles.pricingDetails}>
                    <h3>Prix</h3>
                    <div className={styles.pricingColumns}>
                        <div className={styles.pricingColumn}>
                            <h4>Final</h4>
                            <div className={styles.pricingItem}>
                                <FontAwesomeIcon icon={faMoneyBillWave} /> <strong>Prestation:</strong> {form.pricingDetails.serviceCost} €
                            </div>
                            <div className={styles.pricingItem}>
                                <FontAwesomeIcon icon={faRoad} /> <strong>Péage:</strong> Réel: {form.deliveryCheck.peage} €
                            </div>
                            <div className={styles.pricingItem}>
                                <FontAwesomeIcon icon={faGasPump} /> <strong>Carburant:</strong> Réel: {form.deliveryCheck.carburant} €
                            </div>
                            <div className={styles.pricingItem}>
                                <FontAwesomeIcon icon={faMoneyBillWave} /> <strong>Total:</strong> {form.pricingDetails.serviceCost + form.deliveryCheck.peage + form.deliveryCheck.carburant} €
                            </div>
                        </div>
                        <div className={styles.pricingColumn}>
                            <h4>Estimation</h4>
                            <div className={styles.pricingItem}>
                                <FontAwesomeIcon icon={faMoneyBillWave} /> <strong>Prestation :</strong> {form.pricingDetails.serviceCost} €
                            </div>
                            <div className={styles.pricingItem}>
                                <FontAwesomeIcon icon={faRoad} /> <strong>Péage estimé:</strong> {form.pricingDetails.tollCostEstimate} €
                            </div>
                            <div className={styles.pricingItem}>
                                <FontAwesomeIcon icon={faGasPump} /> <strong>Carburant estimé:</strong> {form.pricingDetails.fuelCostEstimate} €
                            </div>
                            <div className={styles.pricingItem}>
                                <FontAwesomeIcon icon={faMoneyBillWave} /> <strong>Total estimé:</strong> {form.pricingDetails.serviceCost + form.pricingDetails.tollCostEstimate + form.pricingDetails.fuelCostEstimate} €
                            </div>
                        </div>
                    </div>
                    <div className={styles.adminNotes}>
                        <strong>Notes Administratives:</strong> {form.adminNotes || 'AUTOVALIDATION'}
                    </div>
                    <div className={styles.finalizationTime}>
                        <strong>Date de Finalisation:</strong> {form.completedAt ? formatDateTime(form.completedAt) : 'Non finalisé'}
                    </div>
                    {form.status === 'terminated' && (
                        <div className={styles.warningMessage}>
                            Nous avons constaté une différence entre l'estimation des frais de route et ceux déclarés par le convoyeur. Le service exploitation peut prendre contact avec vous.
                        </div>
                    )}
                </div>
            );
        } else {
            return (
                <div className={styles.pricingDetails}>
                    <h3>Prix</h3>
                    <div className={styles.pricingColumn}>
                        <div className={styles.pricingItem}>
                            <FontAwesomeIcon icon={faMoneyBillWave} /> <strong>Prestation estimée:</strong> {form.pricingDetails.serviceCost} €
                        </div>
                        <div className={styles.pricingItem}>
                            <FontAwesomeIcon icon={faRoad} /> <strong>Péage estimé:</strong> {form.pricingDetails.tollCostEstimate} €
                        </div>
                        <div className={styles.pricingItem}>
                            <FontAwesomeIcon icon={faGasPump} /> <strong>Carburant estimé:</strong> {form.pricingDetails.fuelCostEstimate} €
                        </div>
                        <div className={styles.pricingItem}>
                            <FontAwesomeIcon icon={faMoneyBillWave} /> <strong>Total estimé:</strong> {form.pricingDetails.totalPrice} €
                        </div>
                    </div>
                    <div className={styles.infoMessage}>
                        Les frais de route sont une estimation, les montants finaux peuvent être différents et seront connus une fois que le convoyage sera terminé.
                    </div>
                </div>
            );
        }
    } else if (userRole === 'driver') {
        if (form.status === 'terminated' || form.status === 'finished') {
            return (
                <div className={styles.pricingDetails}>
                    <h3>Frais de route</h3>
                    <div className={styles.pricingColumns}>
                        <div className={styles.pricingColumn}>
                            <h4>Final</h4>
                            <div className={styles.pricingItem}>
                                <FontAwesomeIcon icon={faRoad} /> <strong>Péage:</strong> Estimé: {form.pricingDetails.tollCostEstimate} € - Réel: {form.deliveryCheck.peage} €
                            </div>
                            <div className={styles.pricingItem}>
                                <FontAwesomeIcon icon={faGasPump} /> <strong>Carburant:</strong> Estimé: {form.pricingDetails.fuelCostEstimate} € - Réel: {form.deliveryCheck.carburant} €
                            </div>
                        </div>
                        <div className={styles.pricingColumn}>
                            <h4>Estimation</h4>
                            <div className={styles.pricingItem}>
                                <FontAwesomeIcon icon={faRoad} /> <strong>Péage estimé:</strong> {form.pricingDetails.tollCostEstimate} €
                            </div>
                            <div className={styles.pricingItem}>
                                <FontAwesomeIcon icon={faGasPump} /> <strong>Carburant estimé:</strong> {form.pricingDetails.fuelCostEstimate} €
                            </div>
                        </div>
                    </div>
                    {form.status === 'terminated' && (
                        <div className={styles.warningMessage}>
                            Nous avons constaté une différence entre l'estimation des frais de route et ceux déclarés. Le service exploitation peut prendre contact avec vous.
                        </div>
                    )}
                </div>
            );
        } else {
            return (
                <div className={styles.pricingDetails}>
                    <h3>Frais de route</h3>
                    <div className={styles.pricingColumn}>
                        <div className={styles.pricingItem}>
                            <FontAwesomeIcon icon={faRoad} /> <strong>Péage estimé:</strong> {form.pricingDetails.tollCostEstimate} €
                        </div>
                        <div className={styles.pricingItem}>
                            <FontAwesomeIcon icon={faGasPump} /> <strong>Carburant estimé:</strong> {form.pricingDetails.fuelCostEstimate} €
                        </div>
                    </div>
                    <div className={styles.infoMessage}>
                        Les frais de route sont une estimation, les montants finaux peuvent être différents et seront connus une fois que le convoyage sera terminé.
                    </div>
                </div>
            );
        }
    }

    return null;
};

const calculateTotalDue = () => {
    const { serviceCost, tollCostEstimate, fuelCostEstimate, advancePaid, totalPrice } = form.pricingDetails;
    const { peage, carburant } = form.deliveryCheck;

    // Utiliser les valeurs réelles si disponibles, sinon les estimations
    const finalToll = peage || tollCostEstimate;
    const finalFuel = carburant || fuelCostEstimate;

    // Calculer le montant total en fonction des données disponibles
    const totalServiceCost = serviceCost + finalToll + finalFuel;

    // Soustraire les paiements reçus pour obtenir le montant restant à devoir
    const totalDue = totalServiceCost - advancePaid;

    return totalDue.toFixed(2); // Arrondi à 2 décimales
};


    const renderFinancialDetails = () => {
    if (form.pricingDetails && (userRole === 'client' || userRole === 'admin')) {
        return (
            <div className={styles.financialDetails}>
                <h3>Détails Financiers</h3>
                <div><strong>Acompte à verser:</strong> {form.pricingDetails.advancePending.toFixed(2)} €</div>
                <div><strong>Acompte versé:</strong> {form.pricingDetails.advancePaid.toFixed(2)} €</div>
                <div><strong>Total dû:</strong> {calculateTotalDue()} €</div>
            </div>
        );
    }
    return null;
};


    const renderAddress = (details) => {
        if (!details || !details.address) {
            return 'Adresse non disponible';
        }

        const { streetName, number, city, postalCode, streetType } = details.address;
        return `${number} ${streetType} ${streetName}, ${city}, ${postalCode}`;
    };

    const renderPhotos = (checkType) => {
        const photos = checkType === 'pickupCheck'
            ? [
                ['pickupOdoPhoto', 'Photo Compteur'],
                ['faceFrontPhoto', 'Photo Face Avant'],
                ['wheelFrontLeftPhoto', 'Photo Roue Avant Gauche'],
                ['wheelFrontRightPhoto', 'Photo Roue Avant Droite'],
                ['seatFrontLeftPhoto', 'Photo Siège Avant Gauche'],
                ['seatFrontRightPhoto', 'Photo Siège Avant Droit'],
                ['seatRearLeftPhoto', 'Photo Siège Arrière Gauche'],
                ['seatRearRightPhoto', 'Photo Siège Arrière Droit'],
                ['rearLeftPhoto', 'Photo Arrière Gauche'],
                ['rearRightPhoto', 'Photo Arrière Droite'],
                ['wheelRearLeftPhoto', 'Photo Roue Arrière Gauche'],
                ['wheelRearRightPhoto', 'Photo Roue Arrière Droite'],
                ['rearPhoto', 'Photo Arrière'],
                ['trunkOpenPhoto', 'Photo Coffre Ouvert'],
                ['dossierEnlevement', 'Photo Dossier Enlèvement'],
                ['contractPhoto', 'Photo Contrat'],
            ]
            : [
                ['deliveryOdoPhoto', 'Photo Compteur'],
                ['faceFrontPhoto', 'Photo Face Avant'],
                ['wheelFrontLeftPhoto', 'Photo Roue Avant Gauche'],
                ['wheelFrontRightPhoto', 'Photo Roue Avant Droite'],
                ['seatFrontLeftPhoto', 'Photo Siège Avant Gauche'],
                ['seatFrontRightPhoto', 'Photo Siège Avant Droit'],
                ['seatRearLeftPhoto', 'Photo Siège Arrière Gauche'],
                ['seatRearRightPhoto', 'Photo Siège Arrière Droit'],
                ['rearLeftPhoto', 'Photo Arrière Gauche'],
                ['rearRightPhoto', 'Photo Arrière Droite'],
                ['wheelRearLeftPhoto', 'Photo Roue Arrière Gauche'],
                ['wheelRearRightPhoto', 'Photo Roue Arrière Droite'],
                ['rearPhoto', 'Photo Arrière'],
                ['trunkOpenPhoto', 'Photo Coffre Ouvert'],
                ['dossierLivraison', 'Photo Dossier Livraison'],
                ['fraisPhoto', 'Photo Frais'],
            ];

        return (
            <div className={styles.photosRow}>
                {photos.map(([field, label]) => (
                    <div key={field} className={styles.photoItem}>
                        <strong>{label}</strong>
                        {form[checkType][field] ? (
                            <a href={form[checkType][field]} target="_blank" rel="noopener noreferrer">
                                <img 
                                    src={form[checkType][field]} 
                                    alt={label} 
                                    style={{ width: '100px', height: '100px', objectFit: 'cover' }} 
                                />
                            </a>
                        ) : (
                            'Aucune image disponible'
                        )}
                    </div>
                ))}
            </div>
        );
    };

    const renderReserves = (reserves) => {
        return reserves.map((reserve, index) => (
            <div key={index} className={styles.reserveItem}>
                <div><strong>{reserve.part}</strong> - {reserve.subPart}: {reserve.damageType} ({reserve.size})</div>
                <div>Description: {reserve.description}</div>
                <div>
                    {reserve.photo && (
                        <a href={reserve.photo} target="_blank" rel="noopener noreferrer">
                            <img 
                                src={reserve.photo} 
                                alt={`Réserve ${index + 1}`} 
                                style={{ width: '200px', height: '200px', objectFit: 'cover' }} 
                            />
                        </a>
                    )}
                </div>
            </div>
        ));
    };

    const renderCheck = (checkType, title) => {
        if (!form[checkType]) return null;

        return (
            <div className={styles.fullWidthBlock}>
                <h3>{title}</h3>
                <div className={styles.checkDetails}>
                    <div><strong>Kilométrage:</strong> {form[checkType]?.mileage || 'N/A'} km</div>
                    <div><strong>Niveau de Carburant:</strong> {form[checkType]?.fuelLevel || 'N/A'}%</div>
                    <div><strong>Réserves:</strong> {form[checkType]?.reserves?.length > 0 
                        ? renderReserves(form[checkType].reserves)
                        : 'Aucune réserve signalée'}
                    </div>
                    <div>
                        <h4>Photos:</h4>
                        {renderPhotos(checkType)}
                    </div>
                    <div>
                        <h4>Signatures:</h4>
                        <div className={styles.signatureItem}>
                            <strong>Signature de l'enlèvement:</strong>
                            {checkType === 'pickupCheck' ? (
                                form[checkType].enlSignature ? (
                                    <img src={form[checkType].enlSignature} alt="Signature Enlèvement" style={{ width: '200px' }} />
                                ) : 'Aucune signature disponible'
                            ) : (
                                form[checkType].livSignature ? (
                                    <img src={form[checkType].livSignature} alt="Signature Livraison" style={{ width: '200px' }} />
                                ) : 'Aucune signature disponible'
                            )}
                        </div>
                        <div className={styles.signatureItem}>
                            <strong>Signature du conducteur:</strong>
                            {form[checkType].driverSignature ? (
                                <img src={form[checkType].driverSignature} alt="Signature Conducteur" style={{ width: '200px' }} />
                            ) : 'Aucune signature disponible'}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderButtons = () => {
        const buttons = [];

        if (userRole === 'admin') {
            if (form.status === 'pending') {
                buttons.push(
                    <button key="assign" className="btn btn-warning" onClick={() => setIsModalOpen(true)}>
                        Assigner un chauffeur
                    </button>
                );
            } else if (form.status === 'validated') {
                buttons.push(
                    <button key="setPending" className="btn btn-secondary" onClick={() => updateFormStatus(form.customIdF, 'pending')}>
                        Revenir à En attente
                    </button>,
                    <button key="reject" className="btn btn-danger" onClick={() => updateFormStatus(form.customIdF, 'rejected')}>
                        Rejeter
                    </button>
                );
            } else if (form.status === 'terminated') {
                buttons.push(
                    <button key="finalize" className="btn btn-success" onClick={() => setIsFinalizationModalOpen(true)}>
                        Finaliser
                    </button>
                );
            }
        } else if (userRole === 'driver') {
            if (form.status === 'validated') {
                buttons.push(
                    <button key="view-details" className="btn btn-info" onClick={() => navigate(`/driver-assign-details/${encodeURIComponent(form.customIdF)}`)}>
                        Voir Détail & Démarrer Mission
                    </button>,
                    <button key="start" className="btn btn-primary" onClick={() => navigate(`/driver-check-form/${encodeURIComponent(form.customIdF)}`)}>
                        Démarrer
                    </button>
                );
            } else if (form.status === 'started') {
                buttons.push(
                    <button key="close" className="btn btn-danger" onClick={() => navigate(`/driver-check-form-del/${encodeURIComponent(form.customIdF)}`)}>
                        Clôturer la Mission
                    </button>
                );
            }
        }

        return buttons.length > 0 ? (
            <div className={styles.cardActions}>
                {buttons}
            </div>
        ) : null;
    };

    const updateFormStatus = async (customIdF, status) => {
        try {
            await axios.put(`https://ate69.fr:5002/api/convoyage/status/${customIdF}`, { status }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            onFormUpdate();
        } catch (error) {
            console.error('Erreur lors de la mise à jour du statut du convoyage:', error);
        }
    };

    return (
        <div className={`${styles.card} ${styles[form.status]}`}>
            <div className={`${styles.cardHeader} ${styles[`${form.status}Header`]}`}>
                <h2>
                    ID Convoyage: {form.customIdF} | {form.vehicleDetails.brand} {form.vehicleDetails.model} | {form.vehicleDetails.registration} | {form.pickupDetails.address?.city} - {form.deliveryDetails.address?.city}
                </h2>
                <div className={styles.toggleDetailsButton} onClick={toggleDetails}>
                    <FontAwesomeIcon icon={detailsVisible ? faChevronUp : faChevronDown} />
                </div>
            </div>
            {detailsVisible && (
                <div className={styles.cardBody}>
                    <div className={styles.topSection}>
                        <div className={styles.infoClientBlock}>
                            <h3>Infos Client</h3>
                            <div>ID Custom: {form.clientDetails.customIdC}</div>
                            <div>Compagnie: {form.clientDetails.company}</div>
                            <div>Nom: {form.clientDetails.name}</div>
                            <div>Prénom: {form.clientDetails.surname}</div>
                            <div>Téléphone: {form.clientDetails.phone}</div>
                            <div>Email: {form.clientDetails.email}</div>
                        </div>
                        <div className={styles.infoTechnicalBlock}>
                            <h3>Infos Techniques</h3>
                            <div>Date de soumission: {formatDateTime(form.createdAt)}</div>
                            <div>Date de dispo: {formatDateTime(form.vehicleDetails.availabilityDate)}</div>
                            <div>Date d'enlèvement: {formatDateTime(form.pickupDetails?.pickupDate)}</div>
                            <div>Date de livraison: {formatDateTime(form.deliveryDetails?.deliveryDate)}</div>
                            {form.validationDetails && (
                                <>
                                    <div>Date de validation: {formatDateTime(form.validationDetails.date)}</div>
                                    <div>Validé par: {form.validationDetails.validatedBy}</div>
                                </>
                            )}
                            {form.startedAt && (
                                <div className={styles.startDate}>
                                    <span>Démarré le: {formatDateTime(form.startedAt)}</span>
                                    <span className={styles.blinkingDot}></span>
                                    <div>Estimation arrivée: {calculateEstimatedArrival(form.startedAt, form.pricingDetails.duration)}</div>
                                </div>
                            )}
                            {form.completedAt && (
                                <div className={styles.endDate}>
                                    <span>Arrivé le: {formatDateTime(form.completedAt)}</span>
                                    <span>Durée: {calculateDuration(form.startedAt, form.completedAt)}</span>
                                </div>
                            )}
                            {renderFinancialDetails()} {/* Affichage des détails financiers */}
                        </div>
                    </div>
                    <div className={styles.mapContainer}>
                        <Map
                            origin={form.pickupDetails.address ? renderAddress(form.pickupDetails) : 'Adresse de départ indisponible'}
                            destination={form.deliveryDetails.address ? renderAddress(form.deliveryDetails) : 'Adresse de livraison indisponible'}
                        />
                    </div>
                    <div className={styles.infoBlocks}>
                        <div className={styles.infoBlock}>
                            <h3>Infos Chauffeur</h3>
                            {driverInfo ? (
                                <div>
                                    <div>ID Custom Chauffeur: {form.assignedDriver.customIdD}</div>
                                    <div>Nom: {driverInfo.name}</div>
                                    <div>Prénom: {driverInfo.surname}</div>
                                    <div>Téléphone mobile: {driverInfo.mobile}</div>
                                    <div>Email: {driverInfo.email}</div>
                                    <div>Statut: {driverInfo.status}</div>
                                </div>
                            ) : (
                                <div>Chauffeur non assigné ou convoyage en cours de validation et d'assignation</div>
                            )}
                        </div>
                        <div className={styles.infoBlock}>
                            <h3>Infos Enlèvement</h3>
                            <div>Compagnie: {form.pickupDetails.company || 'N/A'}</div>
                            <div>Nom: {form.pickupDetails.name}</div>
                            <div>Prénom: {form.pickupDetails.surname}</div>
                            <div>Email: {form.pickupDetails.email}</div>
                            <div>Téléphone: {form.pickupDetails.phone}</div>
                            <div>Adresse: {renderAddress(form.pickupDetails)}</div>
                        </div>
                        <div className={styles.infoBlock}>
                            <h3>Infos Livraison</h3>
                            <div>Compagnie: {form.deliveryDetails.company || 'N/A'}</div>
                            <div>Nom: {form.deliveryDetails.name}</div>
                            <div>Prénom: {form.deliveryDetails.surname}</div>
                            <div>Email: {form.deliveryDetails.email}</div>
                            <div>Téléphone: {form.deliveryDetails.phone}</div>
                            <div>Adresse: {renderAddress(form.deliveryDetails)}</div>
                        </div>
                        <div className={styles.infoBlock}>
                            <h3>Infos Véhicule</h3>
                            <div>Marque: {form.vehicleDetails.brand}</div>
                            <div>Modèle: {form.vehicleDetails.model}</div>
                            <div>Immatriculation: {form.vehicleDetails.registration}</div>
                            <div>Distance: {form.pricingDetails.distance}</div>
                            <div>Durée estimée: {form.pricingDetails.duration}</div>
                            {form.status === 'started' && (
                                <div>Kilométrage départ: {form.pickupCheck.mileage}</div>
                            )}
                            {form.status === 'terminated' && (
                                <>
                                    <div>Kilométrage départ: {form.pickupCheck.mileage}</div>
                                    <div>Kilométrage arrivée: {form.deliveryCheck.mileage}</div>
                                    <div>Kilométrage parcouru: {mileageDifference}</div>
                                </>
                            )}
                        </div>
                        <div className={styles.infoBlock}>
                            {renderPricingDetails()}
                        </div>
                    </div>
                    {(form.status === 'terminated' || form.status === 'finished') && (
                        <>
                            {renderCheck('pickupCheck', 'État des Lieux Enlèvement')}
                            {renderCheck('deliveryCheck', 'État des Lieux Livraison')}
                        </>
                    )}
                    {form.status === 'started' && (
                        renderCheck('pickupCheck', 'État des Lieux Enlèvement')
                    )}
                    {renderButtons()}
                </div>
            )}
            {isModalOpen && (
                <FormDetailsModal
                    isOpen={isModalOpen}
                    onRequestClose={() => setIsModalOpen(false)}
                    form={form}
                    onFormUpdate={onFormUpdate} 
                />
            )}
            {isFinalizationModalOpen && (
                <FinalizeModal
                    isOpen={isFinalizationModalOpen}
                    onRequestClose={() => setIsFinalizationModalOpen(false)}
                    form={form}
                    onFinalize={handleFinalize}
                />
            )}
        </div>
    );
};

export default FormCard;
