import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import SignaturePad from 'react-signature-canvas';
import ReservesPickup from './ReservesPickup';

function DriverCheckForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const sigPadEnl = useRef({});
    const sigPadDriver = useRef({});
    const [formState, setFormState] = useState({
        mileage: '',
        fuelLevel: '',
        pickupOdoPhoto: null,
        seatFrontLeftPhoto: null,
        wheelFrontLeftPhoto: null,
        faceFrontLeftPhoto: null,
        faceFrontPhoto: null,
        faceFrontRightPhoto: null,
        wheelFrontRightPhoto: null,
        seatFrontRightPhoto: null,
        seatRearRightPhoto: null,
        wheelRearRightPhoto: null,
        rearRightPhoto: null,
        rearPhoto: null,
        trunkOpenPhoto: null,
        rearLeftPhoto: null,
        wheelRearLeftPhoto: null,
        seatRearLeftPhoto: null,
        reserves: [],
        contractPhoto: null,
        enlSignature: '',
        driverSignature: '',
        dossierEnlevement: null,
        hasReserves: false
    });

    const [uploadProgress, setUploadProgress] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            setFormState(prevState => ({ ...prevState, [name]: files[0] }));
        } else {
            setFormState(prevState => ({ ...prevState, [name]: value }));
        }
    };

    const handleSignatureSave = (name, signatureRef) => {
        setFormState(prevState => ({
            ...prevState,
            [name]: signatureRef.current.toDataURL()
        }));
    };

    const handleAddReserve = (reserve) => {
        setFormState(prevState => ({
            ...prevState,
            reserves: [...prevState.reserves, {
                ...reserve,
                photo: reserve.photo instanceof File ? URL.createObjectURL(reserve.photo) : '' // Vérifiez si la photo est bien un fichier
            }]
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        Object.entries(formState).forEach(([key, value]) => {
            if (value !== null && value !== '') {
                if (key === 'reserves') {
                    const cleanedReserves = value.map(reserve => ({
                        ...reserve,
                        photo: reserve.photo ? reserve.photo : '' // Assurez-vous que la photo est une chaîne vide si non disponible
                    }));
                    formData.append(key, JSON.stringify(cleanedReserves));
                } else {
                    formData.append(key, value);
                }
            }
        });

        setLoading(true);

        try {
            const token = localStorage.getItem('token');
            await axios.put(`https://ate69.fr:5003/api/convoyages/${id}/driver-check`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const totalLength = progressEvent.lengthComputable
                        ? progressEvent.total
                        : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    
                    if (totalLength) {
                        const progress = Math.round((progressEvent.loaded * 100) / totalLength);
                        setUploadProgress(progress);
                    }
                }
            });
            alert('Les détails du convoyage ont été mis à jour avec succès.');
            navigate('/dashboard');
        } catch (error) {
            console.error('Échec de la soumission du formulaire:', error);
            alert('Échec de la mise à jour des détails du convoyage.');
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        navigate(-1);
    };

    return (
        <div>
            <h2>Formulaire de Vérification du Conducteur</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Kilométrage :</label>
                    <input type="number" name="mileage" value={formState.mileage} onChange={handleChange} />
                </div>
                <div>
                    <label>Niveau de carburant (%) :</label>
                    <select name="fuelLevel" value={formState.fuelLevel} onChange={handleChange}>
                        <option value="">Sélectionner</option>
                        <option value="0">0%</option>
                        <option value="25">25%</option>
                        <option value="50">50%</option>
                        <option value="75">75%</option>
                        <option value="100">100%</option>
                    </select>
                </div>
                <div>
                    <label>Photo de l'odomètre à l'enlèvement :</label>
                    <input type="file" accept="image/*" capture="environment" name="pickupOdoPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo siège avant gauche :</label>
                    <input type="file" accept="image/*" capture="environment" name="seatFrontLeftPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo roue avant gauche :</label>
                    <input type="file" accept="image/*" capture="environment" name="wheelFrontLeftPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo face avant gauche :</label>
                    <input type="file" accept="image/*" capture="environment" name="faceFrontLeftPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo face avant :</label>
                    <input type="file" accept="image/*" capture="environment" name="faceFrontPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo face avant droite :</label>
                    <input type="file" accept="image/*" capture="environment" name="faceFrontRightPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo roue avant droite :</label>
                    <input type="file" accept="image/*" capture="environment" name="wheelFrontRightPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo siège avant droit :</label>
                    <input type="file" accept="image/*" capture="environment" name="seatFrontRightPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo siège arrière droit :</label>
                    <input type="file" accept="image/*" capture="environment" name="seatRearRightPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo roue arrière droite :</label>
                    <input type="file" accept="image/*" capture="environment" name="wheelRearRightPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo arrière droit :</label>
                    <input type="file" accept="image/*" capture="environment" name="rearRightPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo arrière :</label>
                    <input type="file" accept="image/*" capture="environment" name="rearPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo coffre ouvert :</label>
                    <input type="file" accept="image/*" capture="environment" name="trunkOpenPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo arrière gauche :</label>
                    <input type="file" accept="image/*" capture="environment" name="rearLeftPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo roue arrière gauche :</label>
                    <input type="file" accept="image/*" capture="environment" name="wheelRearLeftPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Photo siège arrière gauche :</label>
                    <input type="file" accept="image/*" capture="environment" name="seatRearLeftPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Y a-t-il des réserves ?</label>
                    <select name="hasReserves" value={formState.hasReserves} onChange={handleChange}>
                        <option value={false}>Non</option>
                        <option value={true}>Oui</option>
                    </select>
                </div>
                {formState.hasReserves && (
                    <div>
                        {formState.reserves.map((reserve, index) => (
                            <div key={index}>
                                <p><strong>Partie Endommagée:</strong> {reserve.part}</p>
                                <p><strong>Sous-partie:</strong> {reserve.subPart}</p>
                                <p><strong>Type de Dommage:</strong> {reserve.damageType}</p>
                                <p><strong>Description:</strong> {reserve.description}</p>
                                {reserve.photo && typeof reserve.photo === 'object' && (
                                    <div>
                                        <img src={URL.createObjectURL(reserve.photo)} alt="Reserve" style={{ width: '50px', height: '50px' }} />
                                    </div>
                                )}
                            </div>
                        ))}
                        <ReservesPickup onAddReserve={handleAddReserve} />
                    </div>
                )}
                <div>
                    <label>Photo du contrat :</label>
                    <input type="file" accept="image/*" capture="environment" name="contractPhoto" onChange={handleChange} />
                </div>
                <div>
                    <label>Signature d'enlèvement :</label>
                    <SignaturePad ref={sigPadEnl} penColor='black' canvasProps={{ width: '500px', height: '200px', className: 'sigCanvas' }} />
                    <button type="button" onClick={() => handleSignatureSave('enlSignature', sigPadEnl)}>Enregistrer la signature d'enlèvement</button>
                </div>
                <div>
                    <label>Signature du conducteur :</label>
                    <SignaturePad ref={sigPadDriver} penColor='black' canvasProps={{ width: '500px', height: '200px', className: 'sigCanvas' }} />
                    <button type="button" onClick={() => handleSignatureSave('driverSignature', sigPadDriver)}>Enregistrer la signature du conducteur</button>
                </div>
                <div>
                    <label>Dossier d'enlèvement :</label>
                    <input type="file" accept="image/*" capture="environment" name="dossierEnlevement" onChange={handleChange} />
                </div>
                <button type="submit">Soumettre</button>
                <button type="button" onClick={handleClose}>Fermer</button>
            </form>

            {/* Affiche la page de chargement */}
            {loading && (
                <div className="loadingOverlay">
                    <div className="loadingContent">
                        <p>Convoyage en cours de soumission... {uploadProgress}%</p>
                        <progress value={uploadProgress} max="100"></progress>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DriverCheckForm;
