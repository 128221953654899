// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard {
  font-family: Arial, sans-serif;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
}

.logout-btn {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cards-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.user-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  width: 30%;
}

.user-table {
  margin-top: 10px;
}

.user-row {
  border-bottom: 1px solid #eee;
  padding: 5px 0;
}

.user-info p, .user-actions button {
  margin: 5px 0;
}

.user-actions button {
  margin-right: 5px;
  padding: 5px 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.user-actions button:nth-child(2) {
  background-color: #dc3545;
}

.user-actions button:nth-child(3) {
  background-color: #ffc107;
}
`, "",{"version":3,"sources":["webpack://./src/css/admin_dash.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".dashboard {\r\n  font-family: Arial, sans-serif;\r\n}\r\n\r\n.dashboard-header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  padding: 10px;\r\n  background-color: #f5f5f5;\r\n}\r\n\r\n.logout-btn {\r\n  padding: 5px 10px;\r\n  background-color: #007bff;\r\n  color: white;\r\n  border: none;\r\n  border-radius: 5px;\r\n  cursor: pointer;\r\n}\r\n\r\n.cards-container {\r\n  display: flex;\r\n  justify-content: space-around;\r\n  padding: 20px;\r\n}\r\n\r\n.user-card {\r\n  border: 1px solid #ddd;\r\n  border-radius: 5px;\r\n  padding: 10px;\r\n  width: 30%;\r\n}\r\n\r\n.user-table {\r\n  margin-top: 10px;\r\n}\r\n\r\n.user-row {\r\n  border-bottom: 1px solid #eee;\r\n  padding: 5px 0;\r\n}\r\n\r\n.user-info p, .user-actions button {\r\n  margin: 5px 0;\r\n}\r\n\r\n.user-actions button {\r\n  margin-right: 5px;\r\n  padding: 5px 10px;\r\n  background-color: #28a745;\r\n  color: white;\r\n  border: none;\r\n  border-radius: 5px;\r\n  cursor: pointer;\r\n}\r\n\r\n.user-actions button:nth-child(2) {\r\n  background-color: #dc3545;\r\n}\r\n\r\n.user-actions button:nth-child(3) {\r\n  background-color: #ffc107;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
