import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Estimate from '../components/Estimate';
import '../Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad, faClock, faEuroSign, faRoadCircleCheck, faGasPump, faCalculator, faCar, faTruckPickup, faTruck } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const [vehicleType, setVehicleType] = useState('');
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [estimateDetails, setEstimateDetails] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const originInputRef = useRef(null);
  const destinationInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const scriptId = 'google-maps-places-script';

    const initAutocomplete = () => {
      if (originInputRef.current) {
        const originAutocomplete = new window.google.maps.places.Autocomplete(originInputRef.current, { types: ['geocode', 'establishment'] });
        originAutocomplete.addListener('place_changed', () => {
          const place = originAutocomplete.getPlace();
          setOrigin(place.formatted_address || place.name);
        });
      }

      if (destinationInputRef.current) {
        const destinationAutocomplete = new window.google.maps.places.Autocomplete(destinationInputRef.current, { types: ['geocode', 'establishment'] });
        destinationAutocomplete.addListener('place_changed', () => {
          const place = destinationAutocomplete.getPlace();
          setDestination(place.formatted_address || place.name);
        });
      }
    };

    if (!window.googleMapsScriptLoaded && !document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => {
        window.googleMapsScriptLoaded = true;
        initAutocomplete();
      };

      script.onerror = () => {
        console.error("Échec du chargement du script Google Maps Places.");
      };
    } else if (window.google && window.google.maps && window.google.maps.places) {
      initAutocomplete();
    }
  }, []);

  const handleEstimate = (details) => {
    setEstimateDetails(details);
  };

  const handleShowAlert = (totalPrice) => {
    if (!showAlert) {
      alert(`Pour les trajets de moins de 50 km, nous proposons une livraison à domicile pour 149€ tout compris pour les clients réguliers. Saviez-vous que vous pouvez économiser ${totalPrice - 149}€ avec notre formule L.A.D ? Contactez-nous pour plus d'informations !`);
      setShowAlert(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const missing = [];
    if (!origin) missing.push('origin');
    if (!destination) missing.push('destination');
    if (!vehicleType) missing.push('vehicleType');

    if (missing.length > 0) {
      setMissingFields(missing);
      setTimeout(() => setMissingFields([]), 2000); // Remove highlight after 2 seconds
    } else {
      setMissingFields([]);
      handleEstimate({ vehicleType, origin, destination });
    }
  };

  const handleBack = () => {
    setEstimateDetails(null);
    setVehicleType('');
    setOrigin('');
    setDestination('');
    setShowAlert(false);
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleRegister = () => {
    navigate('/register');
  };

  const titleAnimation = {
    initial: { y: -20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { type: 'spring', stiffness: 120, delay: 0.2 }
  };

  const subtitleAnimation = {
    initial: { y: -20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { type: 'spring', stiffness: 120, delay: 0.4 }
  };

  return (
    <header className="header" itemScope itemType="http://schema.org/Organization">
      <Helmet>
        <title>Auto Transport Express - Spécialiste du Convoyage Automobile Léger</title>
        <meta name="description" content="Auto Transport Express offre des services de convoyage automobile léger de haute qualité en France et à l'international. Découvrez nos solutions sur mesure pour tous vos besoins de transport." />
        <meta name="keywords" content="convoyage automobile, transport léger, transport international, transport France" />
      </Helmet>

      <div className="header-content" itemProp="name">
        <motion.div
          initial="initial"
          animate="animate"
          variants={titleAnimation}
          className="text-container"
        >
          <motion.h1
            className="main-heading"
            itemProp="headline"
          >
            AUTO TRANSPORT EXPRESS
          </motion.h1>
          <motion.h2
            className="subtitle"
            itemProp="description"
            variants={subtitleAnimation}
          >
            Votre Solution de Convoyage Automobile Léger en France et à l’International
          </motion.h2>
        </motion.div>
        {!estimateDetails ? (
          <form className="estimate-form" onSubmit={handleSubmit}>
            <div className="estimate-intro">
              <h4>Estimez Votre Convoyage en Quelques Clics !</h4>
              <h5>Obtenez une estimation rapide et précise pour le convoyage de votre véhicule en quelques étapes simples.</h5>
            </div>
            <h6 style={{ color: '#444' }}>Type de véhicule ( citadine, berline, utilitaire )</h6>
            <div className={`vehicle-type-icons ${missingFields.includes('vehicleType') ? 'missing-field' : ''}`}>
              <FontAwesomeIcon icon={faCar} onClick={() => setVehicleType('citadine')} className={`vehicle-icon ${vehicleType === 'citadine' ? 'selected' : ''}`} title="Citadine" />
              <FontAwesomeIcon icon={faTruckPickup} onClick={() => setVehicleType('berline')} className={`vehicle-icon ${vehicleType === 'berline' ? 'selected' : ''}`} title="Berline" />
              <FontAwesomeIcon icon={faTruck} onClick={() => setVehicleType('utilitaire')} className={`vehicle-icon ${vehicleType === 'utilitaire' ? 'selected' : ''}`} title="Utilitaire" />
            </div>
            <input
              type="text"
              placeholder="Adresse enlèvement"
              value={origin}
              onChange={(e) => setOrigin(e.target.value)}
              className={missingFields.includes('origin') ? 'missing-field' : ''}
              required
              ref={originInputRef}
            />
            <input
              type="text"
              placeholder="Adresse livraison"
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
              className={missingFields.includes('destination') ? 'missing-field' : ''}
              required
              ref={destinationInputRef}
            />
            <button type="submit">Estimer</button>
          </form>
        ) : (
          <div className="estimate-display">
            <div className="map-container">
              <Estimate
                origin={origin}
                destination={destination}
                vehicleType={vehicleType}
                onCalculate={handleEstimate}
                onShowAlert={handleShowAlert}
              />
            </div>
            <div className="estimate-results">
              <h3 className="estimate-title">
                Estimation du convoyage pour un véhicule de type {vehicleType.toUpperCase()} :
                <br /> De : {origin.toUpperCase()} <br />à : {destination.toUpperCase()}
              </h3>
              <table className="estimate-table">
                <tbody>
                  <tr>
                    <td><FontAwesomeIcon icon={faRoad} /> Distance :</td>
                    <td>{estimateDetails.distance}</td>
                  </tr>
                  <tr>
                    <td><FontAwesomeIcon icon={faClock} /> Durée :</td>
                    <td>{estimateDetails.duration}</td>
                  </tr>
                  <tr>
                    <td><FontAwesomeIcon icon={faEuroSign} /> Tarif de la prestation :</td>
                    <td>{estimateDetails.serviceCost} €</td>
                  </tr>
                  <tr>
                    <td><FontAwesomeIcon icon={faRoadCircleCheck} /> Péage :</td>
                    <td>{estimateDetails.tolls} €</td>
                  </tr>
                  <tr>
                    <td><FontAwesomeIcon icon={faGasPump} /> Carburant :</td>
                    <td>{estimateDetails.fuelCost} €</td>
                  </tr>
                  <tr>
                    <td><FontAwesomeIcon icon={faCalculator} /> Total estimé :</td>
                    <td>{estimateDetails.totalPrice} €</td>
                  </tr>
                </tbody>
              </table>
              <p className="disclaimer">
                Les frais de route sont une estimation. Le carburant est calculé comme si le véhicule au départ était à sec. La facturation finale est toujours en dessous de l'estimation. Le péage est calculé pour une classe 1. En cas de frais supérieurs, l'exploitation vous en informera avant le départ.
              </p>
              <div className="button-container">
                <button className="button" onClick={handleBack}>Retour</button>
                <button className="button" onClick={handleRegister}>Inscription</button>
                <button className="button" onClick={handleLogin}>Connexion</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
