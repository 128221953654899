import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const AdminDriverBook = () => {
    const [events, setEvents] = useState([]);
    const [drivers, setDrivers] = useState([]);

    useEffect(() => {
        fetchDrivers();
    }, []);

    const fetchDrivers = async () => {
        try {
            const response = await axios.get(`https://ate69.fr:5003/api/convoyages/driver/`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            const eventsFromReservations = response.data.filter(reservation => reservation.status === 'validated').map(reservation => ({
                title: `Réservation: ${reservation.vehicleDetails.brand} ${reservation.vehicleDetails.model}`,
                start: moment(reservation.pickupDetails.pickupDate).toDate(),
                end: moment(reservation.deliveryDetails.deliveryDate).toDate(),
                allDay: false,
                resource: reservation,
            }));
            setEvents(eventsFromReservations);
        } catch (error) {
            console.error('Erreur lors de la récupération des réservations:', error);
        }
    };


    const handleSelectDriver = (driverId) => {
        // Implémentez la logique pour sélectionner un chauffeur et afficher/mettre à jour son planning
    };

    const handleSelectSlot = ({ start, end, driverId }) => {
        // Implémentez la logique pour ajouter une indisponibilité ou une réservation
        console.log('Sélectionné:', start, end);
    };

    return (
        <div>
            <h2>Plannings des Chauffeurs</h2>
            {/* Implémentez la sélection et l'affichage des plannings des chauffeurs ici */}
            <Calendar
                localizer={localizer}
                events={events} // Assurez-vous de mettre à jour les événements pour le chauffeur sélectionné
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                selectable
                onSelectSlot={handleSelectSlot}
            />
        </div>
    );
};

export default AdminDriverBook;
