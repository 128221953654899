import React, { useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

function NestorFormClient({ clientInfo }) {
    const initialState = {
        role: '',
        vehicleType: '',
        brand: '',
        model: '',
        registration: '',
        availabilityDate: new Date(),
        pickupDetails: {
            name: clientInfo.name,
            surname: clientInfo.surname,
            email: clientInfo.email,
            phone: clientInfo.phone,
            company: clientInfo.companyName,
            address: clientInfo.companyAddress
        },
        deliveryDetails: {
            name: '',
            surname: '',
            email: '',
            phone: '',
            company: '',
            address: ''
        },
    };

    const [formData, setFormData] = useState(initialState);
    const [step, setStep] = useState(0);

    const handleInputChange = (event, section) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [section]: {
                ...prev[section],
                [name]: value
            }
        }));
    };

    const handleDateChange = (date) => {
        setFormData(prev => ({
            ...prev,
            availabilityDate: date
        }));
    };

    const handleSubmit = async () => {
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            };
            await axios.post('https://ate69.fr:5003/api/convoyages', formData, config);
            alert(`Votre demande de convoyage a bien été envoyée.`);
        } catch (error) {
            alert('Erreur lors de l\'envoi du formulaire: ' + error.message);
        }
    };

    const renderForm = () => {
        switch (step) {
            case 0:
                return (
                    <div>
                        <h2>Bonjour, je suis Nestor! Commençons par vos informations :</h2>
                        <label>Nom: </label>
                        <input type="text" value={formData.pickupDetails.name} onChange={e => handleInputChange(e, 'pickupDetails')} name="name"/>
                        <label>Prénom: </label>
                        <input type="text" value={formData.pickupDetails.surname} onChange={e => handleInputChange(e, 'pickupDetails')} name="surname"/>
                        {/* Plus d'inputs pour email, phone, etc. ici */}
                        <button onClick={() => setStep(1)}>Next</button>
                    </div>
                );
            case 1:
                return (
                    <div>
                        <h3>Êtes-vous l'expéditeur ou le destinataire?</h3>
                        <select name="role" onChange={e => setFormData({...formData, role: e.target.value})} required>
                            <option value="">Sélectionnez votre rôle</option>
                            <option value="expéditeur">Expéditeur</option>
                            <option value="destinataire">Destinataire</option>
                        </select>
                        <button onClick={() => setStep(2)}>Next</button>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <h3>Détails du véhicule</h3>
                        <label>Type de véhicule:</label>
                        <select name="vehicleType" onChange={e => handleInputChange(e, '')} required>
                            <option value="">Sélectionnez le type de véhicule</option>
                            <option value="citadine">Citadine</option>
                            <option value="berline">Berline</option>
                            <option value="utilitaire">Utilitaire</option>
                        </select>
                        <input type="text" name="brand" placeholder="Marque" onChange={e => handleInputChange(e, '')} required />
                        <input type="text" name="model" placeholder="Modèle" onChange={e => handleInputChange(e, '')} required />
                        <input type="text" name="registration" placeholder="Immatriculation" onChange={e => handleInputChange(e, '')} required />
                        <DatePicker
                            selected={formData.availabilityDate}
                            onChange={handleDateChange}
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            placeholderText="Date de disponibilité"
                            required
                        />
                        <button onClick={() => setStep(3)}>Next</button>
                    </div>
                );
            case 3:
                return (
                    <div>
                        <h3>{formData.role === 'expéditeur' ? "Détails de livraison" : "Détails d'enlèvement"}</h3>
                        {/* Render input fields for either deliveryDetails or pickupDetails based on role */}
                        <input type="text" name="address" placeholder="Adresse" onChange={e => handleInputChange(e, formData.role === 'expéditeur' ? 'deliveryDetails' : 'pickupDetails')} required />
                        <button onClick={() => setStep(4)}>Next</button>
                    </div>
                );
            case 4:
                return (
                    <div>
                        <h3>Confirmation des informations</h3>
                        {/* Display all data for confirmation */}
                        <p>Type de véhicule: {formData.vehicleType}</p>
                        <p>Marque: {formData.brand}</p>
                        <p>Modèle: {formData.model}</p>
                        <p>Immatriculation: {formData.registration}</p>
                        <p>Date de disponibilité: {formData.availabilityDate.toLocaleDateString()}</p>
                        <p>{formData.role === 'expéditeur' ? "Adresse de livraison" : "Adresse d'enlèvement"}: {formData.role === 'expéditeur' ? formData.deliveryDetails.address : formData.pickupDetails.address}</p>
                        <button onClick={handleSubmit}>Soumettre</button>
                    </div>
                );
            default:
                return <div>Une erreur est survenue, veuillez recommencer.</div>;
        }
    };

    return <div>{renderForm()}</div>;
}

export default NestorFormClient;
