import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children, role, fonction }) => {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user") || '{}');
  const userRole = user.role || null;
  const userFonction = user.fonction || null;

  // Vérifie si l'utilisateur est authentifié
  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Vérifie si le rôle de l'utilisateur correspond au rôle requis
  if (role && userRole !== role) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Vérifie si la fonction de l'utilisateur correspond à l'une des fonctions requises
  if (fonction && !fonction.includes(userFonction)) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Si toutes les conditions sont remplies, retourne le composant enfant
  return children;
};

export default ProtectedRoute;
