import React from 'react';
import Slider from 'react-slick';
import { Helmet } from 'react-helmet';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import '../Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faUserCheck, faClock, faConciergeBell, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const CustomArrow = ({ className, style, onClick, icon }) => (
  <div
    className={className}
    style={{ ...style, display: "block", background: "rgba(0, 0, 0, 0.5)", borderRadius: "50%", padding: "10px", cursor: "pointer" }}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={icon} size="lg" color="#FFFFFF" />
  </div>
);

const HeaderC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    nextArrow: <CustomArrow icon={faChevronRight} />,
    prevArrow: <CustomArrow icon={faChevronLeft} />,
  };

  return (
    <header className="header" itemScope itemType="http://schema.org/Organization">
      <Helmet>
        <title>Créez Votre Compte ATE69</title>
        <meta name="description" content="Créez votre compte ATE69 en moins d'une minute et profitez de nos services de transport automobile." />
        <meta name="keywords" content="convoyage automobile, inscription, ATE69, transport voiture" />
      </Helmet>
      <div className="carousel-container">
        <Slider {...settings}>
          <div>
            <img src="/images/carousel1.jpg" alt="Créez votre compte ATE69" />
            <div className="carousel-caption">
              <FontAwesomeIcon icon={faUserCheck} size="3x" color="#31BAF0" />
              <h1>Créez votre compte ATE69</h1>
              <p>Profitez de tous les avantages de nos services de transport automobile.</p>
            </div>
          </div>
          <div>
            <img src="/images/carousel2.jpg" alt="Cela vous prendra moins de 1 minute" />
            <div className="carousel-caption">
              <FontAwesomeIcon icon={faClock} size="3x" color="#31BAF0" />
              <h1>Cela vous prendra moins de 1 minute</h1>
              <p>Inscrivez-vous rapidement et facilement pour accéder à nos services.</p>
            </div>
          </div>
          <div>
            <img src="/images/carousel3.jpg" alt="Profitez des services ATE69" />
            <div className="carousel-caption">
              <FontAwesomeIcon icon={faConciergeBell} size="3x" color="#31BAF0" />
              <h1>Profitez des services ATE69</h1>
              <p>Des solutions de transport adaptées à tous vos besoins.</p>
            </div>
          </div>
        </Slider>
      </div>
    </header>
  );
};

export default HeaderC;
