import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function ResetPassword() {
    const { token } = useParams(); // Récupérer le token de l'URL
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
        setError('Les mots de passe ne correspondent pas.');
        return;
    }

    try {
        const response = await fetch(`https://ate69.fr:5001/api/clients/reset-password/${token}`, {
            method: 'PUT', // Utilisez PUT pour la mise à jour
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ newPassword }),
        });

        const data = await response.json();

        if (response.ok) {
            setSuccessMessage('Votre mot de passe a été réinitialisé avec succès.');
            setTimeout(() => navigate('/login'), 3000);
        } else {
            setError(data.message || 'Erreur lors de la réinitialisation du mot de passe.');
        }
    } catch (err) {
        console.error('Erreur lors de la réinitialisation:', err);
        setError('Erreur lors de la réinitialisation du mot de passe.');
    }
};


    return (
        <div>
            <h2>Réinitialisation du mot de passe</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Nouveau mot de passe :</label>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Confirmez le mot de passe :</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Réinitialiser le mot de passe</button>
            </form>
        </div>
    );
}

export default ResetPassword;
