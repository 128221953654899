import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FormCard from '../components/ClientForms/FormCard'; // Vérifiez le chemin d'accès correct

function StartedDriverDetail({ driverId }) {
    const [convoyages, setConvoyages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchDriverConvoyages = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`https://ate69.fr:5003/api/convoyages/driver/${driverId}/started`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                setConvoyages(response.data);
            } catch (err) {
                setError('Échec de la récupération des détails des convoyages');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchDriverConvoyages();
    }, [driverId]);

    if (loading) return <div>Chargement...</div>;
    if (error) return <div>Erreur : {error}</div>;
    if (!convoyages.length) return <div>Aucun détail de convoyage trouvé.</div>;

    return (
        <div>
            <h2>Détails des Convoyages (Démarrés)</h2>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                {convoyages.map((convoyage) => (
                    <FormCard key={convoyage._id} form={convoyage} status="started" />
                ))}
            </div>
        </div>
    );
}

export default StartedDriverDetail;
