import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import '../Formules.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function Formules() {
  const navigate = useNavigate();

  const handleInscriptionClick = () => {
    navigate('/register'); // Assurez-vous que le chemin est correct
    window.scrollTo(0, 0); // Ajout de cette ligne pour faire défiler vers le haut
  };

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0); // Ajout de cette ligne pour faire défiler vers le haut
  };

  return (
    <section id="our-work" className="formules-section" itemScope itemType="http://schema.org/Service">
      <Helmet>
        <title>ATE69 : Découvrez Nos Formules de Convoyage Auto</title>
        <meta
          name="description"
          content="De convoyages occasionnels à des livraisons à domicile, explorez nos solutions de convoyage automobile pour répondre à tous vos besoins."
        />
        <meta
          name="keywords"
          content="Convoyage, convoyeur, auto, Lyon, Rhône, France, Europe, transport, voiture, livraison à domicile"
        />
        <link rel="canonical" href="https://ate69.fr/formules" />
        <html lang="fr" />
      </Helmet>
      <h2 itemProp="name">Découvrez Nos Formules de Convoyage Auto</h2>
      <p id="sous-titre" itemProp="description">
        De convoyages occasionnels à des livraisons à domicile, explorez nos solutions de convoyage automobile pour répondre à tous vos besoins.
      </p>

      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="formules-card" itemScope itemType="http://schema.org/Service">
              <img src={require('../../src/img/img_card6.jpg')} alt="Convoyage Occasionnel" itemProp="image" />
              <h3 itemProp="name">Convoyages occasionnels</h3>
              <p itemProp="description">
                Besoin d’un convoyage unique ou de quelques convoyages annuels ? Notre service de convoyage occasionnel est parfait pour vous. Profitez de la
                flexibilité de notre offre pour transporter vos véhicules en toute sécurité.
              </p>
              <button className="btn btn-primary" onClick={() => handleNavigation('/occasionnel')}>En savoir plus</button>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="formules-card" itemScope itemType="http://schema.org/Service">
              <img src={require('../../src/img/img_card7.jpg')} alt="Convoyages Réguliers" itemProp="image" />
              <h3 itemProp="name">Convoyages réguliers</h3>
              <p itemProp="description">
                Vous prévoyez de réaliser plusieurs convoyages chaque mois ? Notre service de convoyage régulier est conçu pour répondre à vos besoins
                fréquents. Bénéficiez de tarifs avantageux et d'une planification flexible.
              </p>
              <button className="btn btn-primary" onClick={() => handleNavigation('/regulier')}>En savoir plus</button>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="formules-card" itemScope itemType="http://schema.org/Service">
              <img src={require('../../src/img/img_card8.jpg')} alt="Livraison à Domicile" itemProp="image" />
              <h3 itemProp="name">Livraison à domicile</h3>
              <p itemProp="description">
                Offrez à vos clients la commodité de la livraison à domicile de leurs nouveaux véhicules. Avec notre service de livraison à domicile, vous
                pouvez consacrer plus de temps à vos futurs clients tout en offrant un service exceptionnel.
              </p>
              <button className="btn btn-primary" onClick={() => handleNavigation('/lad')}>En savoir plus</button>
            </div>
          </div>
        </div>
        <div className="inscription-section">
          <h6 className="inscription-text">
            <FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
            L'inscription est obligatoire et exclusivement réservée aux professionnels ayant un Kbis et exerçant dans les catégories suivantes :
          </h6>
          <p id="sous-titre" className="inscription-text">
            Concession auto, agence de location auto, assurances, garages, enchères auto, mandataires auto, importateurs/exportateurs auto, carrossiers et préparateurs auto. Toutes les sociétés ayant un lien avec l'automobile ou nécessitant des déplacements de flottes sont également concernées.
          </p>
          <button className="btn btn-primary btn-inscription" onClick={handleInscriptionClick}>Inscription</button>
        </div>
      </div>
    </section>
  );
}

export default Formules;
