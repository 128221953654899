import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faSyncAlt, faStop, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Footer from './Footer';
import '../NotFoundPage.css';

function NotFoundPage() {
  return (
    <div className="not-found-page">
      <Helmet>
        <title>404 - Oups, nous sommes perdus !</title>
        <meta name="description" content="La page que vous recherchez n'existe pas ou a été déplacée." />
      </Helmet>
      <div className="content">
        <div className="roadmap">
          <h1>404</h1>
          <h2>Oups, nous sommes perdus !</h2>
          <div className="instructions">
            <p><FontAwesomeIcon icon={faArrowLeft} /> Dans 300 mètres, tournez à droite...</p>
            <p><FontAwesomeIcon icon={faArrowRight} /> Dans 600 mètres, tournez à gauche...</p>
            <p><FontAwesomeIcon icon={faSyncAlt} /> Dans 20 mètres, faites quatre fois le tour sur vous-même...</p>
            <p><FontAwesomeIcon icon={faStop} /> Dans 10 mètres, arrêtez-vous ! On est complètement perdus !</p>
            <p><FontAwesomeIcon icon={faExclamationTriangle} /> On dirait que la page que vous cherchez a pris un autre chemin.</p>
          </div>
          <Link to="/" className="btn">Retourner à l'accueil</Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default NotFoundPage;
