import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header_C from '../../pages/Header_C';
import Hero_C from '../../pages/Hero_C';
import Footer from '../../pages/Footer';
import '../../css/register.css';

const joursSemaine = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

const ClientRegister = () => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        email: '',
        confirmEmail: '',
        password: '',
        confirmPassword: '',
        name: '',
        surname: '',
        gender: '',
        phone: '',
        mobile: '',
        siret: '',
        companyName: '',
        companyType: '',
        companyAddress: {
            number: '',
            streetType: '',
            streetName: '',
            postalCode: '',
            city: ''
        },
        capital: '',
        openingHours: joursSemaine.map(day => ({
            day,
            closed: day === 'Samedi',
            times: day === 'Samedi' ? [] : [{ from: '09:00', to: '18:00' }]
        }))
    });
    const [errors, setErrors] = useState({});
    const [availableCapitals, setAvailableCapitals] = useState([]);
    const navigate = useNavigate();
    const formRef = useRef(null); // Reference to the form element

    useEffect(() => {
        fetchAvailableCapitals();
    }, []);

    const fetchAvailableCapitals = async () => {
        try {
            const response = await axios.get('https://ate69.fr:5001/api/clients/capitals/available');
            setAvailableCapitals(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des capitales disponibles', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name.startsWith('companyAddress.')) {
            const addressField = name.split('.')[1];
            setFormData(prev => ({
                ...prev,
                companyAddress: {
                    ...prev.companyAddress,
                    [addressField]: value
                }
            }));
        } else if (name.startsWith('openingHours.')) {
            const [_, index, field, subField] = name.split('.');
            setFormData(prev => ({
                ...prev,
                openingHours: prev.openingHours.map((oh, i) =>
                    i === parseInt(index)
                        ? {
                            ...oh,
                            [field]: field === 'closed' ? !oh.closed : value,
                            times: field === 'closed' && !oh.closed
                                ? []
                                : oh.times.map((t, tIndex) =>
                                    tIndex === parseInt(subField) ? { ...t, [field]: value } : t
                                )
                        }
                        : oh
                )
            }));
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const addTimeSlot = (dayIndex) => {
        setFormData(prev => ({
            ...prev,
            openingHours: prev.openingHours.map((oh, i) =>
                i === dayIndex
                    ? {
                        ...oh,
                        times: [...oh.times, { from: '', to: '' }]
                    }
                    : oh
            )
        }));
    };

    const removeTimeSlot = (dayIndex, slotIndex) => {
        setFormData(prev => ({
            ...prev,
            openingHours: prev.openingHours.map((oh, i) =>
                i === dayIndex
                    ? {
                        ...oh,
                        times: oh.times.filter((_, tIndex) => tIndex !== slotIndex)
                    }
                    : oh
            )
        }));
    };

    const validateForm = () => {
        const errors = {};

        if (formData.email !== formData.confirmEmail) {
            errors.email = 'Les emails ne correspondent pas';
        }

        if (formData.password !== formData.confirmPassword) {
            errors.password = 'Les mots de passe ne correspondent pas';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            const response = await axios.post('https://ate69.fr:5001/api/clients', formData);
            if (response.status === 201) {
                // Envoi des emails après l'inscription réussie via le serveur mail
                await axios.post('https://ate69.fr:5006/mail/register_C', { clientData: formData });
                navigate('/ValidationWaitPage');
            } else {
                alert('Réponse inattendue lors de l\'inscription. Veuillez réessayer.');
            }
        } catch (error) {
            console.error("Erreur lors de l'inscription:", error.response ? error.response.data : error.message);
            alert(`Erreur lors de l'inscription: ${error.response ? error.response.data.message : 'Erreur de connexion au serveur.'}`);
        }
    };

    const nextStep = () => {
        if (validateFormStep(step)) {
            setStep(step + 1);
            formRef.current.scrollIntoView({ behavior: 'smooth' }); // Scroll to the form
        }
    };

    const prevStep = () => {
        setStep(step - 1);
        formRef.current.scrollIntoView({ behavior: 'smooth' }); // Scroll to the form
    };

    const validateFormStep = (step) => {
        const stepErrors = {};

        switch (step) {
            case 1:
                if (!formData.email || !formData.confirmEmail) {
                    stepErrors.email = 'Veuillez renseigner votre email.';
                }
                if (!formData.password || !formData.confirmPassword) {
                    stepErrors.password = 'Veuillez renseigner votre mot de passe.';
                }
                if (formData.email !== formData.confirmEmail) {
                    stepErrors.confirmEmail = 'Les emails ne correspondent pas.';
                }
                if (formData.password !== formData.confirmPassword) {
                    stepErrors.confirmPassword = 'Les mots de passe ne correspondent pas.';
                }
                break;
            case 2:
                if (!formData.name) {
                    stepErrors.name = 'Veuillez renseigner votre nom.';
                }
                if (!formData.surname) {
                    stepErrors.surname = 'Veuillez renseigner votre prénom.';
                }
                if (!formData.gender) {
                    stepErrors.gender = 'Veuillez sélectionner votre genre.';
                }
                if (!formData.mobile) {
                    stepErrors.mobile = 'Veuillez renseigner votre téléphone mobile.';
                }
                break;
            case 3:
                if (!formData.siret) {
                    stepErrors.siret = 'Veuillez renseigner le SIRET.';
                }
                if (!formData.companyName) {
                    stepErrors.companyName = 'Veuillez renseigner le nom de l\'entreprise.';
                }
                if (!formData.companyAddress.number || !formData.companyAddress.streetType || !formData.companyAddress.streetName || !formData.companyAddress.postalCode || !formData.companyAddress.city) {
                    stepErrors.companyAddress = 'Veuillez renseigner l\'adresse complète de l\'entreprise.';
                }
                if (!formData.phone) {
                    stepErrors.phone = 'Veuillez renseigner le téléphone fixe de l\'entreprise.';
                }
                if (!formData.companyType) {
                    stepErrors.companyType = 'Veuillez sélectionner le type de société.';
                }
                if (!formData.capital) {
                    stepErrors.capital = 'Veuillez sélectionner une capitale.';
                }
                break;
            case 4:
                formData.openingHours.forEach((oh, index) => {
                    if (!oh.closed) {
                        oh.times.forEach((time, tIndex) => {
                            if (!time.from || !time.to) {
                                stepErrors[`openingHours.${index}.times.${tIndex}.from`] = `Veuillez renseigner les horaires pour ${oh.day}.`;
                            }
                        });
                    }
                });
                break;
            default:
                break;
        }

        setErrors(stepErrors);
        return Object.keys(stepErrors).length === 0;
    };

    return (
        <div>
            <Header_C className="header-full-width" />
            <div className="form-container" ref={formRef}>
                <form onSubmit={handleSubmit}>
                    {step === 1 && (
                        <div className="step">
                            <h2>Informations de connexion</h2>
                            <div>
                                <label htmlFor="email">Email :</label>
                                <input type="email" name="email" id="email" value={formData.email} onChange={handleChange} required />
                                {errors.email && <span className="error">{errors.email}</span>}
                            </div>
                            <div>
                                <label htmlFor="confirmEmail">Confirmez l'Email :</label>
                                <input type="email" name="confirmEmail" id="confirmEmail" value={formData.confirmEmail} onChange={handleChange} required />
                                {errors.confirmEmail && <span className="error">{errors.confirmEmail}</span>}
                            </div>
                            <div>
                                <label htmlFor="password">Mot de passe :</label>
                                <input type="password" name="password" id="password" value={formData.password} onChange={handleChange} required />
                                {errors.password && <span className="error">{errors.password}</span>}
                            </div>
                            <div>
                                <label htmlFor="confirmPassword">Confirmez le mot de passe :</label>
                                <input type="password" name="confirmPassword" id="confirmPassword" value={formData.confirmPassword} onChange={handleChange} required />
                                {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}
                            </div>
                            <button type="button" onClick={nextStep}>Suivant</button>
                        </div>
                    )}

                    {step === 2 && (
                        <div className="step">
                            <h2>Informations personnelles</h2>
                            <div>
                                <label htmlFor="gender">Genre :</label>
                                <select name="gender" id="gender" value={formData.gender} onChange={handleChange} required>
                                    <option value="">Sélectionnez votre genre</option>
                                    <option value="male">Mr</option>
                                    <option value="female">Mme</option>
                                </select>
                                {errors.gender && <span className="error">{errors.gender}</span>}
                            </div>
                            <div>
                                <label htmlFor="name">Nom :</label>
                                <input type="text" name="name" id="name" value={formData.name} onChange={handleChange} required />
                                {errors.name && <span className="error">{errors.name}</span>}
                            </div>
                            <div>
                                <label htmlFor="surname">Prénom :</label>
                                <input type="text" name="surname" id="surname" value={formData.surname} onChange={handleChange} required />
                                {errors.surname && <span className="error">{errors.surname}</span>}
                            </div>
                            <div>
                                <label htmlFor="mobile">Téléphone mobile :</label>
                                <input type="tel" name="mobile" id="mobile" placeholder="+33 (0) x xx xx xx xx" value={formData.mobile} onChange={handleChange} required />
                                {errors.mobile && <span className="error">{errors.mobile}</span>}
                            </div>
                            <button type="button" onClick={prevStep}>Précédent</button>
                            <button type="button" onClick={nextStep}>Suivant</button>
                        </div>
                    )}

                    {step === 3 && (
                        <div className="step">
                            <h2>Informations de l'entreprise</h2>
                            <div>
                                <label htmlFor="siret">SIRET :</label>
                                <input type="text" name="siret" id="siret" value={formData.siret} onChange={handleChange} pattern="\d{14}" title="SIRET doit être composé de 14 chiffres" required />
                                {errors.siret && <span className="error">{errors.siret}</span>}
                            </div>
                            <div>
                                <label htmlFor="companyName">Nom de l'entreprise :</label>
                                <input type="text" name="companyName" id="companyName" value={formData.companyName} onChange={handleChange} required />
                                {errors.companyName && <span className="error">{errors.companyName}</span>}
                            </div>
                            <div>
                                <label htmlFor="companyAddress.number">Numéro :</label>
                                <input type="text" name="companyAddress.number" id="companyAddressNumber" value={formData.companyAddress.number} onChange={handleChange} required />
                                {errors['companyAddress.number'] && <span className="error">{errors['companyAddress.number']}</span>}
                            </div>
                            <div>
                                <label htmlFor="companyAddress.streetType">Type de voie :</label>
                                <input type="text" name="companyAddress.streetType" id="companyAddressStreetType" value={formData.companyAddress.streetType} onChange={handleChange} required />
                                {errors['companyAddress.streetType'] && <span className="error">{errors['companyAddress.streetType']}</span>}
                            </div>
                            <div>
                                <label htmlFor="companyAddress.streetName">Nom de la rue :</label>
                                <input type="text" name="companyAddress.streetName" id="companyAddressStreetName" value={formData.companyAddress.streetName} onChange={handleChange} required />
                                {errors['companyAddress.streetName'] && <span className="error">{errors['companyAddress.streetName']}</span>}
                            </div>
                            <div>
                                <label htmlFor="companyAddress.postalCode">Code postal :</label>
                                <input type="text" name="companyAddress.postalCode" id="companyAddressPostalCode" value={formData.companyAddress.postalCode} onChange={handleChange} required />
                                {errors['companyAddress.postalCode'] && <span className="error">{errors['companyAddress.postalCode']}</span>}
                            </div>
                            <div>
                                <label htmlFor="companyAddress.city">Ville :</label>
                                <input type="text" name="companyAddress.city" id="companyAddressCity" value={formData.companyAddress.city} onChange={handleChange} required />
                                {errors['companyAddress.city'] && <span className="error">{errors['companyAddress.city']}</span>}
                            </div>
                            <div>
                                <label htmlFor="phone">Téléphone fixe de l'entreprise :</label>
                                <input type="tel" name="phone" id="phone" placeholder="+33 (0) x xx xx xx xx" value={formData.phone} onChange={handleChange} required />
                                {errors.phone && <span className="error">{errors.phone}</span>}
                            </div>
                            <div>
                                <label htmlFor="companyType">Type de société :</label>
                                <select name="companyType" id="companyType" value={formData.companyType} onChange={handleChange} required>
                                    <option value="">Sélectionnez un type de société</option>
                                    <option value="Concession auto">Concession auto</option>
                                    <option value="Agence de location auto">Agence de location auto</option>
                                    <option value="Préparateur auto">Préparateur auto</option>
                                    <option value="Mandataire/Importateur/exportateur auto">Mandataire/Importateur/exportateur auto</option>
                                    <option value="Leasing et assurances">Leasing et assurances</option>
                                    <option value="Transporteurs">Transporteurs</option>
                                    <option value="Autres">Autres</option>
                                </select>
                                {errors.companyType && <span className="error">{errors.companyType}</span>}
                            </div>
                            <div>
                                <label htmlFor="capital">Capitale :</label>
                                <p>Chez ATE nous utilisons un système d'identifiant unique via les capitales mondiales. Chaque client peut librement choisir une Capitale disponible, qui servira lors de tout échange avec ATE. Chaque Driver possède également un identifiant représenté par un Pays, quant à l'administration c'est un des continents.</p>
                                <select name="capital" id="capital" value={formData.capital} onChange={handleChange} required>
                                    <option value="">Sélectionnez une capitale</option>
                                    {availableCapitals.map((capital, index) => (
                                        <option key={index} value={capital}>{capital}</option>
                                    ))}
                                </select>
                                {errors.capital && <span className="error">{errors.capital}</span>}
                            </div>
                            <button type="button" onClick={prevStep}>Précédent</button>
                            <button type="button" onClick={nextStep}>Suivant</button>
                        </div>
                    )}

                    {step === 4 && (
                        <div className="step">
                            <h2>Horaires d'ouverture</h2>
                            <div className="opening-hours">
                                {formData.openingHours.map((oh, index) => (
                                    <div key={index} className="opening-hours-row">
                                        <label>{oh.day}</label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                name={`openingHours.${index}.closed`}
                                                checked={oh.closed}
                                                onChange={handleChange}
                                            />
                                            Fermé
                                        </label>
                                        {!oh.closed && (
                                            <>
                                                {oh.times.map((time, tIndex) => (
                                                    <div key={tIndex} className="time-slot">
                                                        <label>De :</label>
                                                        <input
                                                            type="time"
                                                            name={`openingHours.${index}.times.${tIndex}.from`}
                                                            value={time.from || '09:00'}
                                                            onChange={handleChange}
                                                            className="time-input"
                                                        />
                                                        <label>À :</label>
                                                        <input
                                                            type="time"
                                                            name={`openingHours.${index}.times.${tIndex}.to`}
                                                            value={time.to || '18:00'}
                                                            onChange={handleChange}
                                                            className="time-input"
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={() => removeTimeSlot(index, tIndex)}
                                                            className="remove-time-slot"
                                                        >
                                                            Supprimer ce créneau
                                                        </button>
                                                    </div>
                                                ))}
                                                <button
                                                    type="button"
                                                    onClick={() => addTimeSlot(index)}
                                                    className="add-time-slot"
                                                >
                                                    Ajouter un créneau horaire
                                                </button>
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                            {errors.openingHours && <span className="error">{errors.openingHours}</span>}
                            <button type="button" onClick={prevStep}>Précédent</button>
                            <button type="submit">S'inscrire</button>
                        </div>
                    )}
                </form>
                <Hero_C className="footer-full-width" />
            </div>
            <Footer className="footer-full-width" />
        </div>
    );
};

export default ClientRegister;
