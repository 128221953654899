import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';

Modal.setAppElement('#root');

const FormDetailsModal = ({ isOpen, onRequestClose, form, onFormUpdate }) => {
    const [drivers, setDrivers] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState('');
    const [pickupDate, setPickupDate] = useState('');
    const [pickupTime, setPickupTime] = useState('');
    const [deliveryDate, setDeliveryDate] = useState('');
    const [deliveryTime, setDeliveryTime] = useState('');
    const user = JSON.parse(localStorage.getItem('user')); // Récupération du validateur depuis le localStorage

    // Récupération des informations des chauffeurs depuis l'API
    useEffect(() => {
        if (isOpen) {
            const fetchDrivers = async () => {
                try {
                    const { data } = await axios.get('https://ate69.fr:5002/api/drivers', {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                    });
                    setDrivers(data);
                } catch (error) {
                    console.error('Erreur lors de la récupération des chauffeurs:', error);
                    alert('Impossible de récupérer les chauffeurs.');
                }
            };
            fetchDrivers();
        }
    }, [isOpen]);

    // Pré-remplissage de la date et de l'heure d'enlèvement à partir des données du formulaire
    useEffect(() => {
        if (form.vehicleDetails.availabilityDate) {
            const availabilityDate = new Date(form.vehicleDetails.availabilityDate);
            const formattedDate = availabilityDate.toISOString().split('T')[0];
            const formattedTime = availabilityDate.toISOString().split('T')[1].slice(0, 5);
            setPickupDate(formattedDate);
            setPickupTime(formattedTime);
        }
    }, [form]);

    // Calcul automatique de la date de livraison en fonction des horaires de travail
    const calculateDeliveryDateTime = (pickupDateTime, duration) => {
        const [hours, minutes] = duration.match(/\d+/g).map(Number);
        let totalMinutes = (hours * 60) + minutes;

        // Ajout de 30 minutes pour chaque tranche de 2h de trajet
        const extraMinutes = Math.floor(hours / 2) * 30;
        totalMinutes += extraMinutes;

        let deliveryDateTime = new Date(pickupDateTime.getTime() + totalMinutes * 60000);

        // Si la livraison dépasse 19h, on la déplace au lendemain à 9h
        if (deliveryDateTime.getHours() >= 19) {
            deliveryDateTime.setDate(deliveryDateTime.getDate() + 1);
            deliveryDateTime.setHours(9, 0, 0, 0);
        }

        return deliveryDateTime;
    };

    // Calcul de la date et de l'heure de livraison dès que l'heure d'enlèvement change
    useEffect(() => {
        if (pickupDate && pickupTime) {
            const pickupDateTime = new Date(`${pickupDate}T${pickupTime}`);
            const calculatedDeliveryDate = calculateDeliveryDateTime(pickupDateTime, form.pricingDetails.duration);

            setDeliveryDate(calculatedDeliveryDate.toISOString().split('T')[0]);
            setDeliveryTime(calculatedDeliveryDate.toISOString().split('T')[1].slice(0, 5));
        }
    }, [pickupDate, pickupTime, form.pricingDetails.duration]);

    // Fonction pour assigner le chauffeur et valider le convoyage
    const handleAssignDriverAndValidate = async () => {
        if (!selectedDriver || !pickupDate || !pickupTime) {
            alert('Veuillez remplir tous les champs.');
            return;
        }

        const driver = drivers.find(driver => driver._id === selectedDriver);

        if (!driver || !driver.customIdD) {
            alert('Chauffeur non trouvé.');
            return;
        }

        const pickupDateTime = new Date(`${pickupDate}T${pickupTime}`);
        const deliveryDateTime = calculateDeliveryDateTime(pickupDateTime, form.pricingDetails.duration);

        const requestData = {
            pickupDate: pickupDateTime.toISOString(),
            deliveryDate: deliveryDateTime.toISOString(),
            driverCustomId: driver.customIdD,
            driverEmail: driver.email,
            driverPhone: driver.mobile,
            driverName: driver.name,
            driverSurname: driver.surname,
            validatedBy: user.email, // Passe l'email du validateur
        };

        try {
            const token = localStorage.getItem('token');
            await axios.put(
                `https://ate69.fr:5003/api/convoyages/assign-and-validate/${form.customIdF}/${driver.customIdD}`,
                requestData,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            alert('Chauffeur assigné et convoyage validé.');
            onFormUpdate();
            onRequestClose();
        } catch (error) {
            console.error('Erreur lors de l’assignation:', error.response ? error.response.data : error);
            alert('Échec de l’assignation.');
        }
    };

    const renderAddress = (address) => {
        if (!address) {
            return 'Adresse non disponible';
        }
        const { streetName, number, city, postalCode } = address;
        return `${number} ${streetName}, ${city}, ${postalCode}`;
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
            <h2>Détails du Convoyage</h2>
            <p>ID Convoyage: {form.customIdF}</p>
            <p>Nom du Client: {form.clientDetails.name} {form.clientDetails.surname}</p>
            <p>Véhicule: {form.vehicleDetails.brand} {form.vehicleDetails.model} - {form.vehicleDetails.registration}</p>
            <p>Adresse d'enlèvement: {renderAddress(form.pickupDetails.address)}</p>
            <p>Adresse de livraison: {renderAddress(form.deliveryDetails.address)}</p>
            <p>Coût du service: {form.pricingDetails.serviceCost} €</p>
            <p>Coût estimé des péages: {form.pricingDetails.tollCostEstimate} €</p>
            <p>Coût estimé du carburant: {form.pricingDetails.fuelCostEstimate} €</p>
            <p>Prix total estimé: {form.pricingDetails.totalPrice} €</p>
            <p>Distance: {form.pricingDetails.distance} km</p>
            <p>Durée estimée: {form.pricingDetails.duration}</p>
            <p>Statut: {form.status}</p>

            {/* Sélection du chauffeur */}
            <select value={selectedDriver} onChange={(e) => setSelectedDriver(e.target.value)}>
                <option value="">Sélectionnez un chauffeur</option>
                {drivers.map(driver => (
                    <option key={driver._id} value={driver._id}>
                        {driver.name} {driver.surname} - {driver.email}
                    </option>
                ))}
            </select>

            {/* Date et heure de l'enlèvement */}
            <div>
                <p>Date et heure de l'enlèvement :</p>
                <input type="date" value={pickupDate} onChange={(e) => setPickupDate(e.target.value)} />
                <input type="time" value={pickupTime} onChange={(e) => setPickupTime(e.target.value)} />
            </div>

            {/* Date et heure de la livraison */}
            <div>
                <p>Date et heure de livraison :</p>
                <input type="date" value={deliveryDate} disabled />
                <input type="time" value={deliveryTime} disabled />
            </div>

            <button onClick={handleAssignDriverAndValidate}>Assigner et valider</button>
            <button onClick={onRequestClose}>Annuler</button>
        </Modal>
    );
};

export default FormDetailsModal;
