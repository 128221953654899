import React, { useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import styles from '../../css/FinalizeForm.module.css';

const FinalizeForm = ({ isOpen, onRequestClose, form, onFinalize }) => {
    const [peage, setPeage] = useState(form.deliveryCheck.peage || 0);
    const [carburant, setCarburant] = useState(form.deliveryCheck.carburant || 0);
    const [adminNotes, setAdminNotes] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleFinalize = () => {
        const serviceCost = parseFloat(form.pricingDetails.serviceCost);
        const peageValue = parseFloat(peage);
        const carburantValue = parseFloat(carburant);

        if (isNaN(serviceCost) || isNaN(peageValue) || isNaN(carburantValue)) {
            setErrorMessage("Veuillez entrer des valeurs numériques valides pour les coûts.");
            return;
        }

        const totalCost = serviceCost + peageValue + carburantValue;

        // Appeler la fonction onFinalize avec les données mises à jour
        onFinalize(form.customIdF, serviceCost, peageValue, carburantValue, adminNotes);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className={styles.modalContent}
            overlayClassName={styles.modalOverlay}
        >
            <h2>Finalisation du Formulaire</h2>
            <div className={styles.formDetails}>
                <h3>Tarifs et frais de route</h3>
                <div className={styles.inputGroup}>
                    <label>Prestation:</label>
                    <p>{form.pricingDetails.serviceCost} €</p>
                </div>
                <div className={styles.inputGroup}>
                    <label>Péage:</label>
                    <input 
                        type="number" 
                        value={peage} 
                        onChange={(e) => setPeage(e.target.value)} 
                    /> €
                </div>
                <div className={styles.inputGroup}>
                    <label>Carburant:</label>
                    <input 
                        type="number" 
                        value={carburant} 
                        onChange={(e) => setCarburant(e.target.value)} 
                    /> €
                </div>
                <p>Total: {parseFloat(form.pricingDetails.serviceCost) + parseFloat(peage) + parseFloat(carburant)} €</p>
            </div>

            {errorMessage && (
                <div className={styles.errorMessage}>
                    {errorMessage}
                </div>
            )}

            {form.deliveryCheck.fraisPhoto && (
                <div className={styles.photoContainer}>
                    <h3>Photo des frais</h3>
                    <img 
                        src={form.deliveryCheck.fraisPhoto} 
                        alt="Frais" 
                        className={styles.fraisPhoto} 
                    />
                </div>
            )}

            <div className={styles.notesSection}>
                <h3>Annotation de l'administrateur</h3>
                <textarea 
                    placeholder="Ajouter des notes..." 
                    value={adminNotes} 
                    onChange={(e) => setAdminNotes(e.target.value)} 
                />
            </div>

            <div className={styles.finalizeActions}>
                <button onClick={handleFinalize} className={styles.finalizeButton}>
                    <FontAwesomeIcon icon={faCheckCircle} /> Finaliser
                </button>
                <button onClick={onRequestClose} className={styles.cancelButton}>
                    Annuler
                </button>
            </div>
        </Modal>
    );
};

export default FinalizeForm;
